export enum ContentType {
  live = 'live',
}

export interface Content {
  id: string;
  type: ContentType;
  name: string;
  streamUrl?: string;
  description?: string;
  cover?: string;
  scenicPlace?: string;
  scenicLevel?: string;
  backgroundMusic?: string;
  createTime: string;
  updateTime: string;
  categoryId?: string;
}

export interface LoginResponse {
  token: string;
}

export interface Category {
  id: string;
  name: string;
}

export interface Publish {
  id: string;
  name: string;
  url: string;
  deletable: boolean;
  createTime: string;
  updateTime: string;
  backgroundImage?: string
}

export interface Channel {
  id: string;
  name: string;
  publicUrl: string;
  internalUrl: string;
  monitorVideo1PrimaryUrl: string;
  monitorVideo2PrimaryUrl: string;
  monitorOutputPrimaryUrl: string;
  monitorVideo1SecondaryUrl: string;
  monitorVideo2SecondaryUrl: string;
  monitorOutputSecondaryUrl: string;
}

export enum VideoStatus {
  scheduled = 'scheduled',
  live = 'live',
  finished = 'finished',
  vod = 'vod',
}

export interface VideoResponse {
  id: string;
  name: string;
  scheduleTime: string;
  channel?: Channel;
  languages?: string[];
  mediaId?: string;
  vodUrl?: string;
  downloadUrl?: string;
  status: VideoStatus;
  createTime: string;
  updateTime: string;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface CountDownData {
  type: string;
  minute: number;
  second: number;
}

export interface ShowOrBeginCountDown {
  type: string;
  display: boolean;
  minute: number;
  second: number;
}

export interface CaptionData {
  type: string;
  transparency: number;
  captionTitle: string;
  caption1: string;
  caption2: string;
}

export interface ShowCaption {
  type: string;
  display: boolean;
  transparency: number;
  captionTitle: string;
  caption1: string;
  caption2: string;
}

export interface LogoUrlData {
  type: string;
  logoUrl: string;
}

export interface ShowLogo {
  type: string;
  display: boolean;
  logoUrl: string;
}

export interface ScoreData {
  type: string;
  team1: string;
  team2: string;
  score1: number;
  score2: number;
}

export interface ShowScoreCard {
  type: string;
  display: boolean;
  team1: string;
  team2: string;
  score1: number;
  score2: number;
}

export interface RefreshData {
  type: string;
  score1: number;
  score2: number;
  team1: string;
  team2: string;
  display: boolean;
  transparency: number;
  captionTitle: string;
  caption1: string;
  caption2: string;
  showCaption: boolean;
  showScoreCard: boolean;
  showCountDown: boolean;
  showLogo: boolean;
  minute: number;
  second: number;
  beginCountDown: boolean;
  logoUrl: string;
}

export interface GolfCaptionData {
  type: string;
  golfTransparency: number;
  golfCaptionTitle: string;
  golfCaption1: string;
}

export interface ShowGolfCaption {
  type: string;
  display: boolean;
  golfTransparency: number;
  golfCaptionTitle: string;
  golfCaption1: string;
}

export interface ShowGolfIndividualCaption {
  type: string;
  display: boolean;
  playerName: string;
  currentHole: number;
  golfScore: number;
  currentPar: number;
  currentStrokes: number;
  yds: number;
  forBall: string;
}

export interface GolfIndividualCaptionData {
  type: string;
  playerName: string;
  currentHole: number;
  golfScore: number;
  currentPar: number;
  currentStrokes: number;
  yds: number;
  forBall: string;
}

export interface ShowGolfCurrentRank {
  type: string;
  display: boolean;
  position1: number;
  position2: number;
  position3: number;
  position4: number;
  position5: number;
  position6: number;
  name1: string;
  name2: string;
  name3: string;
  name4: string;
  name5: string;
  name6: string;
  golfScore1: number;
  golfScore2: number;
  golfScore3: number;
  golfScore4: number;
  golfScore5: number;
  golfScore6: number;
  thru1: number;
  thru2: number;
  thru3: number;
  thru4: number;
  thru5: number;
  thru6: number;
  today1: number;
  today2: number;
  today3: number;
  today4: number;
  today5: number;
  today6: number;
  showGolfCurrentPlayer: boolean;
}

export interface GolfCurrentRankData {
  type: string;
  position1: number;
  position2: number;
  position3: number;
  position4: number;
  position5: number;
  position6: number;
  name1: string;
  name2: string;
  name3: string;
  name4: string;
  name5: string;
  name6: string;
  golfScore1: number;
  golfScore2: number;
  golfScore3: number;
  golfScore4: number;
  golfScore5: number;
  golfScore6: number;
  thru1: number;
  thru2: number;
  thru3: number;
  thru4: number;
  thru5: number;
  thru6: number;
  today1: number;
  today2: number;
  today3: number;
  today4: number;
  today5: number;
  today6: number;
  showGolfCurrentPlayer: boolean;
}

export interface ShowGolfPersonalAchievement {
  type: string;
  display: boolean;
  playerName: string;
  golfScore: number;
  hole1: number;
  hole2: number;
  hole3: number;
  hole4: number;
  hole5: number;
  hole6: number;
  hole7: number;
  hole8: number;
  hole9: number;
  holeTotal9: number;
  hole10: number;
  hole11: number;
  hole12: number;
  hole13: number;
  hole14: number;
  hole15: number;
  hole16: number;
  hole17: number;
  hole18: number;
  holeTotal18: number;
}

export interface GolfPersonalAchievementData {
  type: string;
  playerName: string;
  golfScore: number;
  hole1: number;
  hole2: number;
  hole3: number;
  hole4: number;
  hole5: number;
  hole6: number;
  hole7: number;
  hole8: number;
  hole9: number;
  holeTotal9: number;
  hole10: number;
  hole11: number;
  hole12: number;
  hole13: number;
  hole14: number;
  hole15: number;
  hole16: number;
  hole17: number;
  hole18: number;
  holeTotal18: number;
}

export interface ShowGolfWeather {
  type: string;
  display: boolean;
  golfWindDirection: string;
  golfWind: string;
  golfWeather: string;
  golfTemperature: number;
}

export interface GolfWeatherData {
  type: string;
  golfWindDirection: string;
  golfWind: string;
  golfWeather: string;
  golfTemperature: number;
}

export interface Score {
  hole: number;
  strokes: number;
  par: number;
}

export interface Round {
  thru: number;
  today?: number;
  status: string;
  total?: number;
  startingTee: number;
  scores: Score[];
}

export interface Player {
  id: string;
  name: string;
  gender: string;
  position: number;
  score?: number;
  strokes: number;
  rounds: Round[];
}

export interface ShowGolfScoreList {
  type: string;
  display: boolean;
  players8: Player8[];
  golfCaptionTitle: string;
  golfCaption1: string;
  currentRound: number;
  roundName: string;
}

export interface GolfScoreListData {
  type: string;
  players8: Player8[];
  golfCaptionTitle: string;
  golfCaption1: string;
  currentRound: number;
  roundName: string;
}

export interface Player8 {
  position: number;
  name: string;
  score?: number;
  thru: number;
  today?: number;
}

export interface Member {
  number?: number;
  name?: string;
  phonetic?: string;
}

export interface TeamMembers {
  member1?: Member;
  member2?: Member;
  member3?: Member;
  member4?: Member;
  member5?: Member;
}

export interface ShowCommentary {
  type: string;
  commentator: string;
  guest: string;
  venue: string;
  showCommentary: boolean;
}

export interface CommentaryData {
  type: string;
  commentator: string;
  guest: string;
  venue: string;
}

export interface ShowBasketballScoreCard {
  type: string;
  team1Logo: string;
  team2Logo: string;
  name1: string;
  name2: string;
  score1: number;
  score2: number;
  word: string;
  showBasketballScoreCard: boolean;
}

export interface BasketballScoreCardData {
  type: string;
  team1Logo: string;
  team2Logo: string;
  name1: string;
  name2: string;
  score1: number;
  score2: number;
  word: string;
}

export interface ShowBasketballLogo {
  type: string;
  basketballLogoUrl: string;
  competition: string;
  showBasketballLogo: boolean;
}

export interface BasketballLogoData {
  type: string;
  basketballLogoUrl: string;
  competition: string;
}

export interface ShowBasketballCaption {
  type: string;
  showBasketballCaption: boolean,
  title: string;
  text: string;
}

export interface BasketballCaptionData {
  type: string;
  title: string;
  text: string;
}

export interface ShowBasketballCompetitor {
  type: string;
  showBasketballCompetitor: boolean;
  team1Members: TeamMembers;
  team2Members: TeamMembers;
  name1: string;
  name2: string;
  team1Logo: string;
  team2Logo: string;
  currentTeam: number;
}

export interface BasketballCompetitorData {
  type: string;
  team1Members: TeamMembers;
  team2Members: TeamMembers;
  name1: string;
  name2: string;
  team1Logo: string;
  team2Logo: string;
  currentTeam: number;
}

export interface ShowBasketballTotalScore {
  type: string;
  showBasketballTotalScore: boolean;
  competition: string;
  word: string;
  name1: string;
  name2: string;
  score1: number;
  score2: number;
  team1Logo: string;
  team2Logo: string;
}

export interface BasketballTotalScoreData {
  type: string;
  competition: string;
  word: string;
  name1: string;
  name2: string;
  score1: number;
  score2: number;
  team1Logo: string;
  team2Logo: string;
}

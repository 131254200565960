import { AUTH_BASE_URL } from '../common/constants';
import axios from 'axios';
import { LoginResponse } from '../common/types';
import * as jwt from 'jsonwebtoken';

const STORAGE_ITEM_TOKEN = 'token';

const LOGIN_URL = `${AUTH_BASE_URL}/v1/token`;

interface JwtPayload {
  sub: string;
  name: string;
  roles: string[];
  exp: number;
}

class AuthService {

  public getToken(): string | null {
    return sessionStorage.getItem(STORAGE_ITEM_TOKEN);
  }

  public checkLogin(): boolean {
    return !!sessionStorage.getItem(STORAGE_ITEM_TOKEN);
  }

  public async login(username: string, password: string): Promise<void> {
    const response = (await axios.post(LOGIN_URL, { username, password })).data as LoginResponse;
    sessionStorage.setItem(STORAGE_ITEM_TOKEN, response.token);
  }

  public async logout(): Promise<void> {
    sessionStorage.removeItem(STORAGE_ITEM_TOKEN);
  }

  public currentUser(): string | undefined {
    const token = sessionStorage.getItem(STORAGE_ITEM_TOKEN);
    if (!token) {
      return undefined;
    }
    const payload = jwt.decode(token as string) as JwtPayload;
    return payload.name;
  }
}

export const authService = new AuthService();

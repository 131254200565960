import './Home.scss';
import 'swiper/swiper-bundle.css';
import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { message } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import { Content } from '../../common/types';
import { GUANGXI_LIVE_PUBLISH_ID, HIDE_LOGIN } from '../../common/constants';
import { publishService } from '../../services/PublishService';
import { ContentView } from './ContentView';
import { Navigation as NavigationView } from './Navigation';
import { authService } from '../../services/AuthService';
import { Login } from './Login';
import rightTurning from './img/rightTurning.png';
import leftTurning from './img/leftTurning.png';
import noLeftTurning from './img/noLeftTurning.png';
import noRightTurning from './img/noRightTurning.png';

// install Swiper modules
SwiperCore.use([Navigation]);

interface Page {
  contents: Content[];
}

interface HomeState {
  login?: boolean;
  page: number;
  totalContents: number;
  pages: Page[];
  scenicPlace?: string;
  search?: string;
  muted: boolean;
}

interface HomeProps {
}

const DEFAULT_PAGE_SIZE = 9;
const DEFAULT_PAGE_INDEX = 0;
const DEFAULT_GLZL = [
  {
    id:"82647127-fcf7-41e2-8bbf-c73159f15bd4",
    type:"live",
    name:"柳州三江程阳侗族八寨景区",
    streamUrl:"http://182.90.224.132:8081/hls/liuzhousanjiang.m3u8",
    description:"程阳八寨景区位于三江侗族自治县林溪镇的林溪河畔，距县城古宜镇19公里，是三江县目前最大的旅游景区，也是广西著名的侗民族风情旅游景区。景区以程阳村的东寨、平甫、吉昌、大寨、平岩村的马安、平寨、岩寨、平坦八个自然屯为总范围（历史上这八个村屯统属程阳村，民间统称为程阳八寨），以马安、平寨、岩寨为核心景区，辖区面积12.55平方公里，居民2197户9700多人，相传大约在700年前程姓和阳姓两家人，最早来到这里居住，随后杨、吴、陈、李、石、黄等姓氏陆续迁来。",
    cover:"https://cbt-upload.obs.cn-north-4.myhuaweicloud.com/ca38cd0d-b847-4c37-aefc-fd4bfc691c90.jpg",
    scenicPlace:"柳州市",
    scenicLevel:"AAAA",
  },
  {
    id:"db76329e-e7ea-407a-95ff-1ab8a7a8621a",
    type:"live",
    name:"三江月也侗寨",
    streamUrl:"http://182.90.224.42:8081/hls/sanjiangyueye.m3u8",
    description:"月也侗寨位于广西柳州市三江县侗乡大道23号，占地面积80亩，总投资约3亿元。侗寨内集美食、住宿、购物、娱乐、休闲、养生等功能为一体，设有哆协、飞梭、牛王、灵鹤、进堂、雁鹅等巷道；建有敬牛广场、月亮广场和月也鼓楼等主要活动场地，以及坐妹三江侗乡鸟巢、坐客三江盛宴堂、三江斗牛场等月也体验区。",
    cover:"https://cbt-upload.obs.cn-north-4.myhuaweicloud.com/5c4418b2-d34b-4c4d-9600-f00b4505b4ce.png",
    scenicPlace:"柳州市",
    scenicLevel:"AAAA"
  },
  {
    id: "bb9841df-50a3-4fef-9e67-a3fa16536284",
    type: "live",
    name: "崇左德天跨国瀑布景区",
    streamUrl: "http://182.90.224.204:8081/hls/detiandapubu.m3u8",
    description: "德天瀑布位于广西壮族自治区崇左市大新县硕龙镇德天村，中国与越南边境处的归春河上游，瀑布气势磅礴、蔚为壮观，与紧邻的越南板约瀑布（“Bản Giốc & Đức Thiên”）相连，是亚洲第一、世界第四大跨国瀑布，年均水流量约为贵州黄果树瀑布的三倍，为中国国家AAAAA级旅游景区 ",
    cover: "https://cbt-upload.obs.cn-north-4.myhuaweicloud.com/f8cbf70d-cae5-4721-a26c-90f03c80e38f.jpg",
    scenicPlace: "崇左市",
    scenicLevel: "AAAAA"
  },
  {
    id:"3f9b1458-3a47-485a-9b96-d3324dc83852",
    type:"live",
    name:"广西规划馆",
    streamUrl:"http://182.90.224.132:8081/hls/gxguihuaguan.m3u8",
    description:"广西规划馆是全国最大的省区级规划馆，广西规划馆是南宁市打造“三馆三街”的项目中最大的展览馆。",
    cover:"https://cbt-upload.obs.cn-north-4.myhuaweicloud.com/615c74cc-eb55-46db-b13d-88195759c66f.jpg",
    scenicPlace:"南宁市",
    scenicLevel:"AAAA"
  },
  {
    id: "23f4cb82-612c-4e1e-876a-f750345bdb7e",
    type: "live",
    name: "南宁之夜景区",
    streamUrl: "http://182.90.224.30:8081/hls/naningzhiye.m3u8",
    description: "“南宁之夜”文旅步行街街区内充分利用吃、喝、玩、乐、购，休、秀、闲、娱、康这十大旅游因素，打造吃中有游，游中有购，购中有娱，娱中有秀的主题式商业街区。",
    cover: "https://cbt-upload.obs.cn-north-4.myhuaweicloud.com/745d5410-6b00-4a79-bf8a-68c9336b919a.png",
    scenicPlace: "南宁市",
    scenicLevel: ""
  },
  {
    id: "698bdaca-099b-4e86-ba24-d0716e14d2b4",
    type: "live",
    name: "桂林两江四湖日月双塔景区",
    streamUrl: "http://182.90.224.55:8081/hls/riyueshuangta.m3u8",
    description: "桂林日月双塔，其中日塔高41米共九层，通体均为纯铜装饰，耗用铜材350吨，金碧辉煌，并有电梯供游客观光乘坐。铜塔所有构件如塔什、瓦面、翘角、斗拱、雀替、门窗、柱梁、天面、地面完全由铜壁画装饰，整座铜塔创下了三项世界之最——世界上最高的铜塔，世界上最高的铜质建筑物，世界上最高的水中塔。 月塔高35米共七层，为琉璃塔。每层的雕花彩绘门窗寓意不同的主题，富含中国传统韵味，通过水下湖底一条10米长的湖底隧道与日塔连接，其地宫之中有桂林明代青花梅瓶大型壁画。杉湖日月双塔已成为观赏桂林城市精华美景的胜地。",
    cover: "https://cbt-upload.obs.cn-north-4.myhuaweicloud.com/67d45d50-1b4a-4309-81d3-da792f7a26ba.png",
    scenicPlace: "桂林市",
    scenicLevel: "AAAAA"
  },
  {
    id: "ab735fcc-d99c-4e90-b4bf-5259ba8646ac",
    type: "live",
    name: "南宁青秀山风景旅游区",
    streamUrl: "http://182.90.224.204:8081/hls/qingxiushan.m3u8",
    description: "青秀山风景区位于广西壮族自治区南宁市青秀区凤岭南路，是国家AAAAA级旅游景区。\n青秀山是南宁市重点开发的风景区，除保护和修复原有的古迹如董泉、撷青岩崖刻，石香灶等外，还新建了不少景点。\n青秀山风景区包括凤凰岭，凤翼岭和青秀山，海拔高度八十二米至二百八十九米，气候宜人，奇山异卉，四季常开，古诗曰：“青山四时常不老，游子天崖觉春好，我携春色上山来，山花片片迎春开。”在古代，青秀山已经是邕南著名的避暑游览胜地。",
    cover: "https://cbt-upload.obs.cn-north-4.myhuaweicloud.com/c143e147-761f-498c-9a29-27713184bf2f.jpg",
    scenicPlace: "南宁市",
    scenicLevel: "AAAAA"
  },
  // {
  //   id:"11ba8937-8f0b-4b95-9b5b-4f17ff766eb2",
  //   type:"live",
  //   name:"靖西通灵大峡谷景区",
  //   streamUrl:"http://182.90.224.212:8081/hls/tonglingdaxiagu.m3u8",
  //   description:"通灵大峡谷国家AAAA级别风景区位于广西百色市靖西市境内，地处北回归线以南，云贵高原南缘，距广西首府南宁230公里，属那亚热带地区。\n通灵大峡谷全长3.8公里，由通灵峡、念八峡及地下暗河、隧道贯通连接，通天彻地，灵气飘逸。峡谷绝壁千仞，幽幽深邃，巨型钟乳石高崖倒悬，古树老藤遮天蔽日，神秘莫测的洞穴、深潭星罗棋布。水帘洞、地下河及气象万千的瀑布，河涧曲回，流水潺潺，满谷青翠欲滴。通灵大峡谷原来是一个盲谷，由于地壳运动的影响，天行地运，形成一个大天坑，四周悬崖峻峭，气势壮观。高深的峡谷，清澈的溪流，苍翠的植被和188.6米的通灵大瀑布交相辉映，使整个峡谷充满了活力和灵性。",
  //   cover:"https://cbt-upload.obs.cn-north-4.myhuaweicloud.com/fb08e5d6-cf56-4262-af34-3085d4e1fc0e.png",
  //   scenicPlace:"百色市",
  //   scenicLevel:"AAAA"
  // },
  {
    id: "bb85ff75-6ed3-46e0-a7b0-99eb396a8c3a",
    type: "live",
    name: "百色靖西市鹅泉旅游景区",
    streamUrl: "http://182.90.224.82:8081/hls/equanlvyou.m3u8",
    description: "靖西鹅泉景区，是靖西著名的八景之一，已有七百多年的历史，是中国西南部的三大名泉之一（大理的蝴蝶泉、桂平西山的乳泉），又是亚洲第一大跨国瀑布——德天瀑布的源头。",
    cover: "https://cbt-upload.obs.cn-north-4.myhuaweicloud.com/5e1e3d00-8675-42eb-a572-8c0ba9401e99.png",
    scenicPlace: "百色市",
    scenicLevel: "AAAA"
  },
  {
    id: "bddd1e4c-39fc-40da-bc68-5df6bbc08ca9",
    type: "live",
    name: "北海银滩景区",
    streamUrl: "http://182.90.224.118:8081/hls/yintan.m3u8",
    description: "北海银滩是北海市的著名的旅游景点，位于广西壮族自治区北海市银海区，占地面积约38平方公里。\n西起冠头岭，东至大冠沙，由西区、东区和海域沙滩区组成，东西绵延约24公里，海滩宽度在30--3000米之间，陆地面积12平方公里，总面积约38平方公里。\n北海银滩面积超过大连、烟台、青岛、厦门和北戴河海滨浴场沙滩的总和，而平均坡度仅为0.05。\n沙滩均由高品位的石英砂堆积而成，在阳光的照射下，洁白、细腻的沙滩会泛出银光，故称银滩，北海银滩以其“滩长平、沙细白、水温净、浪柔软、无鲨鱼”等特点，而被称为“天下第一滩”。",
    cover: "https://cbt-upload.obs.cn-north-4.myhuaweicloud.com/acda2dfc-4d70-4cca-bc7b-6d8a2432a059.jpg",
    scenicPlace: "北海市",
    scenicLevel: "AAAA"
  }
  // {
  //   id: "85bfa046-957e-4141-941d-39b49f614cef",
  //   type: "live",
  //   name: "靖西市旧州景区",
  //   streamUrl: "http://182.90.224.212:8081/hls/jiuzhoujingqu.m3u8",
  //   description: "旧州景区位于广西壮族自治区靖西市市区以南8公里，四面被喀斯特地貌群山层层环抱，古城在两条河流的汇集处依水而建，年平均气温19.9℃，四季宜人。“山水赛桂林，气候似昆明”，人文古迹众多，壮族风情浓郁，素有“壮族活的博物馆”之美誉。2018年成功评定为国家AAAA级景区。",
  //   cover: "https://cbt-upload.obs.cn-north-4.myhuaweicloud.com/6199bfe8-e3e0-4b28-bde7-f2e919962a62.png",
  //   scenicPlace: "百色市",
  //   scenicLevel: "AAAA"
  // },
  // {
  //   id: "5517a505-19c2-4b67-9131-58a62adbb86d",
  //   type: "live",
  //   name: "崇左大新德天﹒老木棉景区",
  //   streamUrl: "http://182.90.224.64:8081/hls/laomumian.m3u8",
  //   description: "老木棉景区位于广西壮族自治区崇左市大新县硕龙镇隘江村，距亚洲第一、世界第二大跨国瀑布——德天大瀑布及中越自由贸易区仅6公里，是大德天景区内的重要景点之一。",
  //   cover: "https://cbt-upload.obs.cn-north-4.myhuaweicloud.com/120cbd5c-c8ef-4635-80c7-3d8952ac30ed.png",
  //   scenicPlace: "崇左市",
  //   scenicLevel: "AAAA"
  // }
] as Content[];

function getPages(contents: Content[]): Page[] {
  const pages: Page[] = [];
  for (let i = 0; i < contents.length; i += DEFAULT_PAGE_SIZE) {
    pages.push({
      contents: contents.slice(i, Math.min(i + DEFAULT_PAGE_SIZE, contents.length)),
    });
  }
  return pages;
}

function getUrlParams(): { page: number; scenicPlace?: string; search?: string } {
  const params = new URLSearchParams(window.location.search);
  return {
    page: params.get('page') ? Number(params.get('page')) - 1 : DEFAULT_PAGE_INDEX,
    scenicPlace: params.get('scenicPlace') || undefined,
    search: params.get('search') || undefined,
  };
}

function updateOrDeleteUrlParam<T extends boolean | number | string | undefined>(params: URLSearchParams, paramName: string, currentValue: T, defaultValue: T) {
  if (currentValue === defaultValue) {
    params.delete(paramName);
  } else {
    params.set(paramName, String(currentValue));
  }
}

export class Home extends React.Component<HomeProps, HomeState> {
  private publishId = GUANGXI_LIVE_PUBLISH_ID;
  private swiper?: SwiperClass;

  public constructor(props: HomeProps) {
    super(props);
    this.state = {
      page: DEFAULT_PAGE_INDEX,
      totalContents: 0,
      pages: [],
      muted: true,
    };
  }

  public async componentDidMount() {
    document.title = await publishService.getPublishName(this.publishId);
    this.setState({
      ...getUrlParams(),
      login: HIDE_LOGIN ? true : authService.checkLogin(),
      muted: true,
    }, async () => {
      if (this.state.login) {
        await this.refresh();
      }
    });
  }

  public render() {
    return (
      <div className="Home">
        {
          this.state.login === undefined ? null :
              !this.state.login ?
                  <Login onLogin={(username: string, password: string) => this.login(username, password)}/> :
                  <>
                    {
                      <NavigationView
                          visible={true}
                          scenicPlace={this.state.scenicPlace}
                          pageIndex={this.state.page}
                          pageSize={DEFAULT_PAGE_SIZE}
                          totalContents={this.state.totalContents}
                          handleCityChanged={city => this.handleCityChanged(city)}
                          handleSearch={search => this.handleSearch(search)}
                          handlePageChanged={pageIndex => this.handlePageChanged(pageIndex)}
                      />
                    }
                    <div className="SwiperDiv">
                      <img src={ this.state.page === 0 ? noLeftTurning : leftTurning }
                           alt={'left'}
                           onClick={() => this.handleSildeClick(this.state.page - 1)}/>
                      <Swiper
                          className="Swiper"
                          navigation={true}
                          onSwiper={swiper => this.swiper = swiper}
                          onSlideChange={swiper => this.onSlideChange(swiper)}
                          allowTouchMove={false}
                      >
                        {
                          this.state.pages.map((page, index) =>
                              <SwiperSlide className="SwiperSlide"
                                           key={index}>
                                {
                                  ({ isActive }) => page.contents.map((content) =>
                                      <ContentView
                                          key={content.id}
                                          content={content}
                                          autoPlay={true}
                                          muted={this.state.muted}
                                          active={isActive}
                                      />
                                  )
                                }
                              </SwiperSlide>
                          )
                        }
                      </Swiper>
                      <img src={ this.state.page === Math.ceil(this.state.totalContents/DEFAULT_PAGE_SIZE) - 1 ? noRightTurning : rightTurning }
                           alt={'right'}
                           onClick={() => this.handleSildeClick(this.state.page + 1)}/>
                    </div>
                  </>
        }
      </div>
    );
  }

  private handleSildeClick(newPage: number) {
    if (this.swiper?.activeIndex !== newPage) {
      this.swiper?.slideTo(newPage);
    }
  }

  private async handleSearch(search?: string): Promise<void> {
    this.setState({
      search: search,
      scenicPlace: undefined,
    }, () => this.refresh());
  }

  private onSlideChange(swiper: SwiperClass): void {
    this.handlePageChanged(swiper.activeIndex);
  }

  private async handleCityChanged(city: string): Promise<void> {
    this.setState({
      search: undefined,
      scenicPlace: city === '全部' ? undefined : city,
    }, () => this.refresh());
  }

  private handlePageChanged(pageIndex: number): void {
    this.setState({
      page: pageIndex,
    }, () => {
      if (this.swiper?.activeIndex !== this.state.page) {
        this.swiper?.slideTo(this.state.page);
      }
      this.updateUrlParams();
    });
  }

  private async login(username: string, password: string): Promise<void> {
    try {
      await authService.login(username, password);
      this.setState({
        login: true,
      });
      await this.refresh();
    } catch (e) {
      message.error(`登录失败`);
    }
  }

  private async refresh() {
    let contents: Content[] = [];
    if (this.state.scenicPlace === '广旅专栏') {
      contents = DEFAULT_GLZL
    } else {
      contents = await publishService.getContents(this.publishId, this.state.scenicPlace, this.state.search);
    }
    this.setState({
      page: 0,
      totalContents: contents.length,
      pages: getPages(contents),
    }, () => {
      this.handlePageChanged(this.state.page);
      this.updateUrlParams();
      this.swiper?.update();
    });
  }

  private updateUrlParams(): void {
    const url = new URL(window.location.href);
    updateOrDeleteUrlParam(url.searchParams, 'page', this.state.page + 1, DEFAULT_PAGE_INDEX + 1);
    updateOrDeleteUrlParam(url.searchParams, 'scenicPlace', this.state.scenicPlace || undefined, undefined);
    updateOrDeleteUrlParam(url.searchParams, 'search', this.state.search || undefined, undefined);
    window.history.replaceState(null, '', url.toString());
  }
}

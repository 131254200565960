import './Login.scss';
import React from 'react';
import { Button, Form, Input } from 'antd';

interface FormValues {
  username: string;
  password: string;
}

export interface LoginProps {
  onLogin: (username: string, password: string) => void;
}

const DEFAULT_USERNAME = 'guangxilive';

export class Login extends React.Component<LoginProps> {

  public render() {
    return (
      <div className="Login">
        <Form
          initialValues={{
            username: DEFAULT_USERNAME
          }}
          layout="vertical"
          onFinish={(values: FormValues) => this.handleLogin(values)}>
          <Form.Item label="用户名" name="username">
            <Input size="large" placeholder="用户名" readOnly={true} />
          </Form.Item>
          <Form.Item label="密码" name="password" rules={[{ required: true, message: '密码不能为空' }]}>
            <Input.Password size="large" placeholder="密码" />
          </Form.Item>
          <Form.Item>
            <Button size="large" type="primary" htmlType="submit">登录</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }

  private handleLogin(values: FormValues) {
    this.props.onLogin(values.username, values.password);
  }
}

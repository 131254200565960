import videojs, { VideoJsPlayerOptions } from 'video.js';

export function createPlayer(video: HTMLVideoElement, audio: HTMLAudioElement, options: VideoJsPlayerOptions): videojs.Player {
  const player = videojs(video, options);
  player.on('play', () => {
    audio.play().catch(error => console.error(`Failed to play audio: ${error}`));
  });
  player.on('pause', () => {
    audio.pause();
  });
  (player as any).muted = (muted?: boolean) => {
    if (typeof muted === 'undefined') {
      return audio.muted;
    } else {
      audio.muted = muted;
      if (!muted && !audio.paused) {
        audio.play();
      }
      player.trigger('volumechange');
    }
  };
  (player as any).volume = (volume?: number) => {
    if (typeof volume === 'undefined') {
      if ((player as any).__cache_volume === undefined) {
        (player as any).__cache_volume = audio.volume;
      }
      return (player as any).__cache_volume;
    } else {
      (player as any).__cache_volume = volume;
      audio.volume = volume;
      player.trigger('volumechange');
    }
  };
  return player;
}

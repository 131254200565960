import React from 'react';

export class Home extends React.Component {

  public render(): JSX.Element {
    return (
       <div className="InfoHome">

       </div>
    );
  }
}
import logo from './logo.png'
import welcome from './welcome.png'
import './Login.scss'
import React from 'react';
import { Button, Form, Input, message } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { LoginRequest } from '../../../common/types';
import { authService } from '../../../services/AuthService';

export class Login extends React.Component<RouteComponentProps>{

  public render(): JSX.Element {
    return (
      <div className="InfoLogin">
        <img className="Logo" src={logo} alt=""/>
        <img className="Welcome" src={welcome} alt=""/>
        <div className="LoginForm">
          <button className="AccountLogin">账号登录</button>
          <em> | </em>
          <button className="MobileLogin">手机登录</button>
          <Form
            layout="vertical"
            onFinish={(values: LoginRequest) => this.handleLogin(values)}>
            <Form.Item name="username">
              <Input size="large" placeholder="用户名" />
            </Form.Item>
            <Form.Item name="password">
              <Input.Password size="large" placeholder="密码" />
            </Form.Item>
            <button type="button" className="ResetPW">忘记密码</button>
            <button type="button" className="Register">立即注册</button>
            <Form.Item>
              <Button size="large" type="primary" htmlType="submit">登录</Button>
            </Form.Item>
          </Form>
        </div>
        <div className="Report">
          <span>北京国际云转播科技有限公司 版权所有  京ICP备2020044134号-3</span>
        </div>
      </div>
    );
  }

  private async handleLogin(values: LoginRequest) {
    try {
      await authService.login(values.username, values.password);
      this.props.history.push('/info/home');
    } catch (error) {
      console.error(error);
      return message.error('登录失败');
    }
  }
}
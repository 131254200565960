import React from 'react';
import { Select, Input, InputNumber, Form, Table, Typography, Image, Row, Col, Slider, Switch } from 'antd';
import './golf.scss';
import { Player, Score } from "../../common/types";
import { io, Socket } from 'socket.io-client';
import { golfService } from "../../services/GolfService";
import logo from "./logo.png";
import { CAPTION_BASE_URL, CAPTION_SHOW_URL } from "../../common/constants";
import { CaptionPreview } from "./captionPreview";
import { notNull } from "../../common/utils";
import * as uuid from "uuid";
import { FormInstance } from "antd/es/form";

interface golfProps {
    socket: Socket
}
interface golfState {
    totalScore: Score[];
    showGolfIndividualCaption: boolean;
    showGolfLogo: boolean;
    golfLogoUrl: string;
    golfCaptionTitle: string;
    golfCaption1: string;
    golfTransparency: number;
    showGolfCaption: boolean;
    golfWindDirection: string;
    golfWind: string;
    golfWeather: string;
    golfTemperature: number;
    showGolfWeather: boolean;
    showGolfPersonalAchievement: boolean;
    showGolfCurrentRank: boolean;
    showForBall: boolean;
    currentRound: number;
    players: Player[];
    currentPlayer?: string;
    golfScore?: number;
    currentHole: number;
    currentPar: number;
    yds: number;
    forBall: string;
    currentStrokes: number;
    playerName: string;
    position1: number;
    position2: number;
    position3: number;
    position4: number;
    position5: number;
    position6: number;
    name1: string;
    name2: string;
    name3: string;
    name4: string;
    name5: string;
    name6: string;
    golfScore1?: number;
    golfScore2?: number;
    golfScore3?: number;
    golfScore4?: number;
    golfScore5?: number;
    golfScore6?: number;
    thru1: number;
    thru2: number;
    thru3: number;
    thru4: number;
    thru5: number;
    thru6: number;
    today1?: number;
    today2?: number;
    today3?: number;
    today4?: number;
    today5?: number;
    today6?: number;
    hole1: number;
    hole2: number;
    hole3: number;
    hole4: number;
    hole5: number;
    hole6: number;
    hole7: number;
    hole8: number;
    hole9: number;
    holeTotal9: number;
    hole10: number;
    hole11: number;
    hole12: number;
    hole13: number;
    hole14: number;
    hole15: number;
    hole16: number;
    hole17: number;
    hole18: number;
    holeTotal18: number;
}
const { Option } = Select;
const DEFAULT_PAR = [4, 5, 3, 4, 3, 4, 4, 5, 4, 4, 3, 4, 4, 5, 4, 3, 5, 4];
const DEFAULT_HOLES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
const DEFAULT_YDS = {
    '1': [387, 506, 183, 312, 173, 391, 397, 529, 404, 297, 148, 363, 406, 520, 286, 148, 580, 440],
    '2':  [295, 384, 167, 226, 136, 328, 337, 441, 313, 253, 125, 304, 327, 418, 207, 115, 434, 326]
}

let roomId : string;
const SOCKET_PATH = '/captionserver/socket.io';
let socket: Socket;
if (new URLSearchParams(window.location.search).get('id') !== null) {
    roomId = notNull(new URLSearchParams(window.location.search).get('id'));
} else {
    const url = new URL(window.location.href);
    if (url.toString().includes('golf')) {
        roomId = uuid.v4();
        url.searchParams.set('id', roomId);
        window.history.replaceState(null, '', url.toString());
    }
}

export class GolfController extends React.Component<golfProps, golfState> {
    private time1Ref : React.RefObject<HTMLSpanElement> = React.createRef();
    private time2Ref : React.RefObject<HTMLSpanElement> = React.createRef();
    private golfLogoUrlFormRef = React.createRef<FormInstance>();
    private captionFormRef = React.createRef<FormInstance>();
    private weatherFormRef = React.createRef<FormInstance>();
    public constructor(props: golfProps) {
        super(props);
        this.state = {
            totalScore: [],
            showGolfIndividualCaption: false,
            showGolfLogo: false,
            golfLogoUrl: '',
            golfCaptionTitle: '',
            golfCaption1: '',
            golfTransparency: 0,
            showGolfCaption: false,
            golfWindDirection: '',
            golfWind: '',
            golfWeather: '',
            golfTemperature: 30,
            showGolfWeather: false,
            showGolfPersonalAchievement: false,
            showGolfCurrentRank: false,
            showForBall: true,
            currentRound: 0,
            players: [],
            golfScore: 0,
            currentHole: 1,
            currentPar: 1,
            yds: 0,
            forBall: '1st shot',
            currentStrokes: 1,
            playerName: '',
            position1: 1,
            position2: 2,
            position3: 3,
            position4: 4,
            position5: 5,
            position6: 0,
            name1: '',
            name2: '',
            name3: '',
            name4: '',
            name5: '',
            name6: '',
            golfScore1: 0,
            golfScore2: 0,
            golfScore3: 0,
            golfScore4: 0,
            golfScore5: 0,
            golfScore6: 0,
            thru1: 0,
            thru2: 0,
            thru3: 0,
            thru4: 0,
            thru5: 0,
            thru6: 0,
            today1: 0,
            today2: 0,
            today3: 0,
            today4: 0,
            today5: 0,
            today6: 0,
            hole1: 0,
            hole2: 0,
            hole3: 0,
            hole4: 0,
            hole5: 0,
            hole6: 0,
            hole7: 0,
            hole8: 0,
            hole9: 0,
            holeTotal9: 0,
            hole10: 0,
            hole11: 0,
            hole12: 0,
            hole13: 0,
            hole14: 0,
            hole15: 0,
            hole16: 0,
            hole17: 0,
            hole18: 0,
            holeTotal18: 0,
        }
    }

    public async componentDidMount(): Promise<void> {
        document.title = '云导播-高级图文';
        this.joinRoom();
        this.setState({
            players: await golfService.getPlayers(),
        }, () => {
            console.log()
        });
        setInterval(() => {
            let date = new Date();
            notNull(this.time1Ref.current).innerHTML = date.getFullYear() + '年' + (date.getMonth() + 1) + '月' + date.getDate() + '日';
            notNull(this.time2Ref.current).innerHTML = date.getHours() + ':' + (date.getMinutes() < 10 ? ('0' + date.getMinutes()) : date.getMinutes()) + ':' + (date.getSeconds() < 10 ? ('0' + date.getSeconds()) : date.getSeconds());
        },1000);
    }

    private joinRoom(): void {
        socket = io(CAPTION_BASE_URL, {
            path: SOCKET_PATH,
            transports: ['websocket'],
            query: {
                roomId: roomId
            }
        });
    }

    public readonly playersListColumns = [
        {
            key: 'position',
            title: '排名',
            render: (text: unknown, player: Player): JSX.Element => <span>{ player.position }</span>
        },
        {
            key: 'name',
            title: '姓名',
            render: (text: unknown, player: Player): JSX.Element => <span>{ player.name }</span>
        },
        {
            key: 'score',
            title: '总分',
            render: (text: unknown, player: Player): JSX.Element => <span>{ player.score }</span>
        },
        {
            key: 'operate',
            title: '操作',
            render: (text: unknown, player: Player): JSX.Element => <button onClick={() => this.handlePlayerChange(player.id)}>选择</button>
        },
    ]

    public readonly columns = [
        {
            key: 'position',
            title: 'position',
            render: (text: unknown, player: Player): JSX.Element => <span>{ player.position }</span>
        },
        {
            key: 'name',
            title: 'name',
            render: (text: unknown, player: Player): JSX.Element => <span>{ player.name }</span>
        },
        {
            key: 'score',
            title: 'score',
            render: (text: unknown, player: Player): JSX.Element => <span>{ player.score }</span>
        },
        {
            key: 'thru',
            title: 'thru',
            render: (text: unknown, player: Player): JSX.Element => <span>{ player.rounds[this.state.currentRound].thru }</span>
        },
        {
            key: 'today',
            title: 'today',
            render: (text: unknown, player: Player): JSX.Element => <span>{ player.rounds[this.state.currentRound].today }</span>
        },
    ]

    public render(): JSX.Element {
        return (
            <div className='golfHome'>
                <div className='golfTop'>
                    <div className='topContent'>
                        <img className='logo' alt={'logo'} src={logo}/>
                        <Typography.Paragraph
                            className='copy'
                            copyable={{
                                text: `${CAPTION_SHOW_URL}?id=${roomId}`,
                                icon: [<button className='iconButton'>复制输出地址</button>],
                                tooltips: ['复制', '复制成功']}}/>
                    </div>
                </div>
                <div className='previewDiv'>
                    <div className='leftController'>
                        <div className='golfCompetitor'>
                            <div className='golfCompetitorDiv'>
                                <p>参赛名单</p>
                                <button>刷新数据</button>
                            </div>
                            <div className='golfCompetitorTableDiv'>
                                <Table
                                    scroll={{scrollToFirstRowOnChange: true, y: '300px'}}
                                    pagination={{defaultPageSize: 8}}
                                    size={"small"}
                                    dataSource={ this.state.players}
                                    rowKey={ player => player.id}
                                    columns={ this.playersListColumns }
                                    onChange={(pageConfig, b,c) => {
                                        console.log(c)}}
                                />
                            </div>
                        </div>
                        <Form
                            ref={this.golfLogoUrlFormRef}
                            className='golfLogoUrlDiv'
                        >
                            <div className='golfLogoUrl'>
                                <p>角标</p>
                            </div>
                            <div className='golfLogoUrlController'>
                                <div>
                                    <div style={{marginTop: '5px', width: '85%'}}>
                                        <p style={{display: 'flex', float: 'left', margin: 'auto 20px'}}>图片地址：</p>
                                        <Form.Item
                                            name='logoUrl'
                                            validateStatus="warning"
                                            help={'建议格式：*.png;*.jpeg;*.jpg;*.bmp'}
                                        >
                                            <Input
                                                style={{background: '#17242D', color: '#FFFFFF'}}
                                                value={this.state.golfLogoUrl}
                                                placeholder={'请输入图片链接地址'}
                                                bordered={false}
                                                onChange={(e) => {this.setState({golfLogoUrl: e.target.value})}}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div>
                                        <p style={{display: 'flex', float: 'left', margin: '20px'}}>图片预览：</p>
                                        <Image
                                            style={{marginTop: '20px', marginLeft: '20px'}}
                                            width={80}
                                            src={this.state.golfLogoUrl}
                                            fallback={logo}
                                            preview={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'right', marginTop: '8px'}}>
                                <button onClick={() => this.showGolfLogo()}
                                        style={{background: `${this.state.showGolfLogo ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                                    {this.state.showGolfLogo ? '隐藏' : '显示'}
                                </button>
                                <button onClick={() => this.setGolfLogo()}
                                        style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                                    刷新
                                </button>
                            </div>
                        </Form>
                    </div>
                    <div className='previewContentDiv'>
                        <div className='previewTop'>
                            <div className='topDiv'>
                                <span>画面预览</span>
                                <span ref={this.time1Ref}/>
                                <span ref={this.time2Ref}/>
                            </div>
                        </div>
                        <div className='preview'>
                            <CaptionPreview
                                roomId={roomId}
                            />
                        </div>
                    </div>
                    <div className='rightController'>
                        <Form
                            ref={this.weatherFormRef}
                            className='golfWeather'>
                            <div className='golfWeatherDiv'>
                                <p>天气</p>
                            </div>
                            <div className='scoreCardController'>
                                <div className='aWeather'>
                                    <div style={{display: 'flex', float: 'left'}}>
                                        <p style={{margin: '5px 10px auto 24px'}}>天气：</p>
                                        <Form.Item
                                            name="weather"
                                            style={{width: '50%'}}
                                            rules={[{ required: true, message: '不能为空' },
                                                { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                                { max: 10, message: '天气不超过10个字' }]}
                                        >
                                            <Input
                                                value={this.state.golfWeather}
                                                maxLength={10}
                                                bordered={false}
                                                onChange={(e) => this.setState({ golfWeather: e.target.value })}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div style={{display: 'flex', float: 'left'}}>
                                        <p style={{margin: '5px 10px auto 24px'}}>风力：</p>
                                        <Form.Item
                                            name="wind"
                                            style={{width: '50%'}}
                                            rules={[{ required: true, message: '不能为空' },
                                                { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                                { max: 10, message: '风力不超过10个字' }]}
                                        >
                                            <Input
                                                value={this.state.golfWind}
                                                maxLength={10}
                                                bordered={false}
                                                onChange={(e) => this.setState({ golfWind: e.target.value })}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className='aWeather'>
                                    <div style={{display: 'flex', float: 'left'}}>
                                        <p style={{margin: 'auto 10px auto 24px'}}>温度：</p>
                                        <InputNumber
                                            min={0}
                                            max={50}
                                            precision={0}
                                            bordered={false}
                                            value={this.state.golfTemperature}
                                            style={{width: '50%'}}
                                            onChange={(value: number) => this.setState({ golfTemperature: value === null ? 0 : value })}
                                        />
                                    </div>
                                    <div style={{display: 'flex', float: 'left', marginTop: '27px'}}>
                                        <p style={{margin: '5px 10px auto 24px'}}>风向：</p>
                                        <Form.Item
                                            name="windDirection"
                                            style={{width: '50%'}}
                                            rules={[{ required: true, message: '不能为空' },
                                                { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                                { max: 10, message: '风向不超过10个字' }]}
                                        >
                                            <Input
                                                value={this.state.golfWindDirection}
                                                maxLength={10}
                                                bordered={false}
                                                onChange={(e) => this.setState({ golfWindDirection: e.target.value })}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'right', marginTop: '15px'}}>
                                <button onClick={() => this.showWeather()}
                                        style={{background: `${this.state.showGolfWeather ? '#4F5E65' : '#31C3A2'}`, borderRadius: '6px'}}>
                                    {this.state.showGolfWeather ? '隐藏' : '显示'}
                                </button>
                                <button onClick={() => this.setWeather()}
                                        style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                                    刷新
                                </button>
                            </div>
                        </Form>
                        <div className='golfTotalRank'>
                            <div className='golfTotalRankDiv'>
                                <p>总排名</p>
                                <p>回合</p>
                                <Select  style={{ width: '90px', height: '32px', background: '#17242D'}}
                                         bordered={false}
                                         defaultValue={this.state.currentRound}
                                         onChange={(value: number) => this.handleRoundChange(value)}>
                                    <Option value={0}>1</Option>
                                    <Option value={1}>2</Option>
                                </Select>
                            </div>
                            <div className='golfTotalRankTableDiv'>
                                <Table
                                    scroll={{scrollToFirstRowOnChange: true, y: '200px'}}
                                    pagination={{defaultPageSize: 8}}
                                    size={"small"}
                                    dataSource={ this.state.players}
                                    rowKey={ player => player.id}
                                    columns={ this.columns }
                                    onChange={(pageConfig, b,c) => {
                                        console.log(c)}}
                                />
                            </div>
                            <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'right', marginTop: '20px'}}>
                                <button onClick={() => this.showGolfIndividualCaption()}
                                        style={{background: `${this.state.showGolfIndividualCaption ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                                    {this.state.showGolfIndividualCaption ? '隐藏' : '显示'}
                                </button>
                                <button style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                                    刷新
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='golfController'>
                    <div className='golfIndividualCaption'>
                        <div className='golfIndividualScoreCard'>
                            <p>单洞成绩</p>
                        </div>
                        <div style={{width: '100%', display: 'flex', float: 'left', fontSize: '0.1566rem', alignItems: 'center', lineHeight: '0.2rem', marginTop: '10px'}}>
                            <div style={{marginLeft: '25px', width: '50px'}}>洞序号</div>
                            <Select  style={{ width: '90px', height: '32px', background: '#17242D'}}
                                     bordered={false}
                                     value={this.state.currentHole}
                                     onChange={(value: number) => this.handleHoleChange(value)}>
                                {
                                    DEFAULT_HOLES.map((c, index) =>
                                        <Option key={index} value={c}>{c}</Option>)
                                }
                            </Select>
                            <div style={{marginLeft: '25px', width: '50px'}}>score</div>
                            <InputNumber
                                bordered={false}
                                value={this.state.golfScore}
                                onChange={(value: number) => {this.setState({golfScore: value === null ? 0 : value})}}
                            />
                        </div>
                        <div style={{display: 'flex', float: 'left', fontSize: '0.1566rem', alignItems: 'center', lineHeight: '0.2rem', marginTop: '10px'}}>
                            <div style={{marginLeft: '25px', width: '50px'}}>标准杆</div>
                            <InputNumber
                                bordered={false}
                                value={this.state.currentPar}
                                onChange={(value: number) => {this.setState({currentPar: value === null ? 1 : value})}}
                            />
                            <div style={{marginLeft: '25px', width: '50px'}}>当前杆</div>
                            <InputNumber
                                bordered={false}
                                value={this.state.currentStrokes}
                                onChange={(value: number) => {this.setState({currentStrokes: value === null ? 1 : value})}}
                            />
                        </div>
                        <div style={{width: '100%', display: 'flex', float: 'left', fontSize: '0.1566rem', alignItems: 'center', lineHeight: '0.2rem', marginTop: '10px'}}>
                            <div style={{marginLeft: '25px', width: '50px'}}>距离</div>
                            <InputNumber
                                bordered={false}
                                value={this.state.yds}
                                onChange={(value: number) => {this.setState({yds: value === null ? 0 : value})}}
                            />
                            <div style={{marginLeft: '25px', width: '50px'}}>
                                <Switch defaultChecked
                                        onChange={(checked: boolean) => this.handleShowForBallChange(checked)} />
                            </div>
                            <Input
                                bordered={false}
                                style={{width: '90px'}}
                                value={this.state.forBall}
                                onChange={(e) => {this.setState({forBall: e.target.value})}}
                            />
                        </div>
                        <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'right', marginTop: '48px'}}>
                            <button onClick={() => this.showGolfIndividualCaption()}
                                    style={{background: `${this.state.showGolfIndividualCaption ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                                {this.state.showGolfIndividualCaption ? '隐藏' : '显示'}
                            </button>
                            <button onClick={() => this.setGolfIndividualCaption()}
                                    style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                                刷新
                            </button>
                        </div>
                    </div>
                    <div className='golfCurrentRank'>
                        <div className='golfCurrentRankDiv'>
                            <p>实时排名</p>
                        </div>
                        <div style={{display: 'flex', float: 'left', fontSize: '0.1566rem', alignItems: 'center', lineHeight: '0.2rem', marginTop: '10px'}}>
                            <div style={{marginLeft: '5px', width: '50px', textAlign: 'center'}}>前五名</div>
                            <div style={{marginLeft: '15px', width: '50px', textAlign: 'center'}}>名次</div>
                            <div style={{marginLeft: '25px', width: '50px', textAlign: 'center'}}>姓名</div>
                            <div style={{marginLeft: '25px', width: '50px', textAlign: 'center'}}>总分</div>
                            <div style={{marginLeft: '14px', width: '50px', textAlign: 'center'}}>完成洞</div>
                            <div style={{marginLeft: '12px', width: '50px', textAlign: 'center'}}>今日得分</div>
                        </div>
                        <div style={{display: 'flex', float: 'left', fontSize: '0.1333rem', alignItems: 'center', lineHeight: '0.18rem', marginTop: '10px'}}>
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '73px'}}
                                value={this.state.position1}
                                onChange={(value: number) => {this.setState({position1: value === null ? 0 : value})}}
                            />
                            <Input
                                bordered={false}
                                style={{height: '22px', width: '80px', marginLeft: '10px'}}
                                value={this.state.name1}
                                onChange={(e) => {this.setState({name1: e.target.value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '10px'}}
                                value={this.state.golfScore1}
                                onChange={(value: number) => {this.setState({golfScore1: value === null ? 0 : value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '12px'}}
                                value={this.state.thru1}
                                onChange={(value: number) => {this.setState({thru1: value === null ? 0 : value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '10px'}}
                                value={this.state.today1}
                                onChange={(value: number) => {this.setState({today1: value === null ? 0 : value})}}
                            />
                        </div>
                        <div style={{display: 'flex', float: 'left', fontSize: '0.1333rem', alignItems: 'center', lineHeight: '0.18rem', marginTop: '5px'}}>
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '73px'}}
                                value={this.state.position2}
                                onChange={(value: number) => {this.setState({position2: value === null ? 0 : value})}}
                            />
                            <Input
                                bordered={false}
                                style={{height: '22px', width: '80px', marginLeft: '10px'}}
                                value={this.state.name2}
                                onChange={(e) => {this.setState({name2: e.target.value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '10px'}}
                                value={this.state.golfScore2}
                                onChange={(value: number) => {this.setState({golfScore2: value === null ? 0 : value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '12px'}}
                                value={this.state.thru2}
                                onChange={(value: number) => {this.setState({thru2: value === null ? 0 : value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '10px'}}
                                value={this.state.today2}
                                onChange={(value: number) => {this.setState({today2: value === null ? 0 : value})}}
                            />
                        </div>
                        <div style={{display: 'flex', float: 'left', fontSize: '0.1333rem', alignItems: 'center', lineHeight: '0.18rem', marginTop: '5px'}}>
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '73px'}}
                                value={this.state.position3}
                                onChange={(value: number) => {this.setState({position3: value === null ? 0 : value})}}
                            />
                            <Input
                                bordered={false}
                                style={{height: '22px', width: '80px', marginLeft: '10px'}}
                                value={this.state.name3}
                                onChange={(e) => {this.setState({name3: e.target.value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '10px'}}
                                value={this.state.golfScore3}
                                onChange={(value: number) => {this.setState({golfScore3: value === null ? 0 : value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '12px'}}
                                value={this.state.thru3}
                                onChange={(value: number) => {this.setState({thru3: value === null ? 0 : value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '10px'}}
                                value={this.state.today3}
                                onChange={(value: number) => {this.setState({today3: value === null ? 0 : value})}}
                            />
                        </div>
                        <div style={{display: 'flex', float: 'left', fontSize: '0.1333rem', alignItems: 'center', lineHeight: '0.18rem', marginTop: '5px'}}>
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '73px'}}
                                value={this.state.position4}
                                onChange={(value: number) => {this.setState({position4: value === null ? 0 : value})}}
                            />
                            <Input
                                bordered={false}
                                style={{height: '22px', width: '80px', marginLeft: '10px'}}
                                value={this.state.name4}
                                onChange={(e) => {this.setState({name4: e.target.value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '10px'}}
                                value={this.state.golfScore4}
                                onChange={(value: number) => {this.setState({golfScore4: value === null ? 0 : value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '12px'}}
                                value={this.state.thru4}
                                onChange={(value: number) => {this.setState({thru4: value === null ? 0 : value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '10px'}}
                                value={this.state.today4}
                                onChange={(value: number) => {this.setState({today4: value === null ? 0 : value})}}
                            />
                        </div>
                        <div style={{display: 'flex', float: 'left', fontSize: '0.1333rem', alignItems: 'center', lineHeight: '0.18rem', marginTop: '5px'}}>
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '73px'}}
                                value={this.state.position5}
                                onChange={(value: number) => {this.setState({position5: value === null ? 0 : value})}}
                            />
                            <Input
                                bordered={false}
                                style={{height: '22px', width: '80px', marginLeft: '10px'}}
                                value={this.state.name5}
                                onChange={(e) => {this.setState({name5: e.target.value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '10px'}}
                                value={this.state.golfScore5}
                                onChange={(value: number) => {this.setState({golfScore5: value === null ? 0 : value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '12px'}}
                                value={this.state.thru5}
                                onChange={(value: number) => {this.setState({thru5: value === null ? 0 : value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '10px'}}
                                value={this.state.today5}
                                onChange={(value: number) => {this.setState({today5: value === null ? 0 : value})}}
                            />
                        </div>
                        <div style={{display: 'flex', float: 'left', fontSize: '0.1333rem', alignItems: 'center', lineHeight: '0.18rem', marginTop: '5px'}}>
                            <div style={{marginLeft: '5px', width: '50px', fontSize: '0.1566rem'}}>个人排名</div>
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '18px'}}
                                value={this.state.position6}
                                onChange={(value: number) => {this.setState({position6: value === null ? 0 : value})}}
                            />
                            <Input
                                bordered={false}
                                style={{height: '22px', width: '80px', marginLeft: '10px'}}
                                value={this.state.name6}
                                onChange={(e) => {this.setState({name6: e.target.value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '10px'}}
                                value={this.state.golfScore6}
                                onChange={(value: number) => {this.setState({golfScore6: value === null ? 0 : value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '12px'}}
                                value={this.state.thru6}
                                onChange={(value: number) => {this.setState({thru6: value === null ? 0 : value})}}
                            />
                            <InputNumber
                                size={"small"}
                                bordered={false}
                                style={{height: '22px', width: '50px', marginLeft: '10px'}}
                                value={this.state.today6}
                                onChange={(value: number) => {this.setState({today6: value === null ? 0 : value})}}
                            />
                        </div>
                        <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'right', marginTop: '15px'}}>
                            <button onClick={() => this.showGolfCurrentRank()}
                                    style={{background: `${this.state.showGolfCurrentRank ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                                {this.state.showGolfCurrentRank ? '隐藏' : '显示'}
                            </button>
                            <button onClick={() => this.setGolfCurrentRank()}
                                    style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                                刷新
                            </button>
                        </div>
                    </div>
                    <div className='golfPersonalAchievement'>
                        <div className='golfPersonalAchievementDiv'>
                            <p>个人总成绩</p>
                        </div>
                        <div className='golfPersonalAchievementContent'>
                            <div className='golfPersonalPar1Div'>
                                <div className='golfPersonalPar1'>
                                    <div className='spanPar1'><span>标准杆</span></div>
                                    {
                                        DEFAULT_PAR.slice(0, 9).map((c, index) =>
                                            <div key={index} style={{width: '55px', textAlign: 'center'}}><span>{c}</span></div>
                                        )
                                    }
                                    <div style={{width: '50px', textAlign: 'center'}}><span>{this.parTotal(DEFAULT_PAR, 0, 9)}</span></div>
                                </div>
                            </div>
                            <div className='golfPersonalStrokes1Div'>
                                <div className='golfPersonalStrokes1'>
                                    <div className='spanStrokes1'>成绩</div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole1}
                                            onChange={(value: number) => {this.setState({hole1: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole2}
                                            onChange={(value: number) => {this.setState({hole2: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole3}
                                            onChange={(value: number) => {this.setState({hole3: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole1}
                                            onChange={(value: number) => {this.setState({hole1: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole4}
                                            onChange={(value: number) => {this.setState({hole4: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole5}
                                            onChange={(value: number) => {this.setState({hole5: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole6}
                                            onChange={(value: number) => {this.setState({hole6: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole7}
                                            onChange={(value: number) => {this.setState({hole7: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole8}
                                            onChange={(value: number) => {this.setState({hole8: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.holeTotal9}
                                            onChange={(value: number) => {this.setState({holeTotal9: value === null ? 0 : value})}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='golfPersonalPar2Div'>
                                <div className='golfPersonalPar2'>
                                    <div className='spanPar2'>标准杆</div>
                                    {
                                        DEFAULT_PAR.slice(9, 18).map((c, index) =>
                                            <div key={index} style={{width: '55px', textAlign: 'center'}}><span>{c}</span></div>
                                        )
                                    }
                                    <div style={{width: '50px', textAlign: 'center'}}><span>{this.parTotal(DEFAULT_PAR, 9, 18)}</span></div>
                                </div>
                            </div>
                            <div className='golfPersonalStrokes2Div'>
                                <div className='golfPersonalStrokes2'>
                                    <div className='spanStrokes2'>成绩</div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole10}
                                            onChange={(value: number) => {this.setState({hole10: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole11}
                                            onChange={(value: number) => {this.setState({hole11: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole12}
                                            onChange={(value: number) => {this.setState({hole12: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole13}
                                            onChange={(value: number) => {this.setState({hole13: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole14}
                                            onChange={(value: number) => {this.setState({hole14: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole15}
                                            onChange={(value: number) => {this.setState({hole15: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole16}
                                            onChange={(value: number) => {this.setState({hole16: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole17}
                                            onChange={(value: number) => {this.setState({hole17: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '55px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.hole18}
                                            onChange={(value: number) => {this.setState({hole18: value === null ? 0 : value})}}
                                        />
                                    </div>
                                    <div style={{width: '60px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.holeTotal18}
                                            onChange={(value: number) => {this.setState({holeTotal18: value === null ? 0 : value})}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='golfPersonalTotalDiv'>
                                <div className='golfPersonalTotal'>
                                    <div className='totalDiv'>总分</div>
                                    <div style={{width: '60px', height: '32px' , margin: 'auto'}}>
                                        <InputNumber
                                            bordered={false}
                                            style={{width: '50px', height: '32px'}}
                                            value={this.state.golfScore}
                                            onChange={(value: number) => {this.setState({golfScore: value === null ? 0 : value})}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'right', marginTop: '16px'}}>
                            <button onClick={() => this.showGolfPersonalAchievement()}
                                    style={{background: `${this.state.showGolfPersonalAchievement ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                                {this.state.showGolfPersonalAchievement ? '隐藏' : '显示'}
                            </button>
                            <button onClick={() => this.setGolfPersonalAchievement()}
                                    style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                                刷新
                            </button>
                        </div>
                    </div>
                    <Form
                        ref={this.captionFormRef}
                        className='golfCaptionDiv'>
                        <div className='golfCaption'>
                            <p>字幕条</p>
                        </div>
                        <div className='golfCaptionController'>
                            <div style={{width: '100%', height: '100%'}}>
                                <div style={{paddingTop: '10px'}}>
                                    <p style={{margin: '3px 33px auto 20px', display: 'flex', float: 'left'}}>标题：</p>
                                    <Form.Item
                                        name="golfCaptionTitle"
                                        rules={[{ required: true, message: '确认为空？', warningOnly: true },
                                            { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                            { max: 4, message: '标题不超过4个字' }]}
                                    >
                                        <Input
                                            placeholder={'不超过4个字'}
                                            value={this.state.golfCaptionTitle}
                                            style={{width: '90%', background: '#17242D', color: '#FFFFFF'}}
                                            bordered={false}
                                            maxLength={4}
                                            onChange={(e) => this.setState({ golfCaptionTitle: e.target.value })}
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <p style={{margin: '3px 25px auto 20px', display: 'flex', float: 'left'}}>文字1：</p>
                                    <Form.Item
                                        name="golfCaption1"
                                        rules={[{ required: true, message: '确认为空？', warningOnly: true },
                                            { pattern: /^\S.*\S$|(^\S{0,1}\S$)/, message: '首尾不能为空格' },
                                            { max: 14, message: '文字1不超过14个字' }]}
                                    >
                                        <Input
                                            placeholder={'不超过14个字'}
                                            value={this.state.golfCaption1}
                                            style={{width: '90%', background: '#17242D', color: '#FFFFFF'}}
                                            bordered={false}
                                            maxLength={14}
                                            onChange={(e) => this.setState({ golfCaption1: e.target.value })}
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <p style={{margin: '3px 25px auto 20px', display: 'flex', float: 'left'}}>透明度：</p>
                                    <Row style={{width: '60%'}}>
                                        <Col span={15}>
                                            <Slider
                                                min={0}
                                                max={100}
                                                onChange={(value: number) => this.setState({golfTransparency: value > 100 ? 100 : value < 0 ? 0 : value})}
                                                value={this.state.golfTransparency}
                                            />
                                        </Col>
                                        <Col span={3}>
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                precision={0}
                                                bordered={false}
                                                style={{margin: '0 5px', background: '#17242D', color: '#FFFFFF'}}
                                                value={this.state.golfTransparency}
                                                onChange={value => this.setState({golfTransparency: value === null ? 0 : value > 100 ? 100 : value < 0 ? 0 : value})}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <div style={{display: 'flex', float: 'right', width: '100%', justifyContent: 'right', marginTop: '16px'}}>
                            <button onClick={() => this.showGolfCaption()}
                                    style={{background: `${this.state.showGolfCaption ? '#4F5E65' : '#05A905'}`, borderRadius: '6px'}}>
                                {this.state.showGolfCaption ? '隐藏' : '显示'}
                            </button>
                            <button onClick={() => this.setGolfCaption()}
                                    style={{marginRight: '8%', marginLeft: '20px', background: '#F85640', borderRadius: '6px'}}>
                                刷新
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

    private parTotal(defaultPar: number[], start: number, end: number): number {
        let parTotal = 0;
        defaultPar.slice(start, end).forEach(value => {
            parTotal += value;
        })
        return parTotal;
    }

    private async handleRoundChange(value: number): Promise<void> {
        this.setState({
            currentRound: value
        }, async () => {
            this.setState({
                players: await golfService.getPlayers(),
            });
        })
    }

    private handleHoleChange(value: number): void {
        const player = this.state.players.find(player => player.id === this.state.currentPlayer );
        if (player) {
            this.setState({
                currentHole: value
            }, () => {
                this.setState({
                    currentPar: player.rounds[this.state.currentRound].scores[this.state.currentHole].par,
                    currentStrokes: player.rounds[this.state.currentRound].scores[this.state.currentHole].strokes,
                    yds: player.gender === '1' ? DEFAULT_YDS['1'][this.state.currentHole] : DEFAULT_YDS['2'][this.state.currentHole],
                    forBall: this.forBall(player.rounds[this.state.currentRound].scores[this.state.currentHole].par, player.rounds[this.state.currentRound].scores[this.state.currentHole].strokes)
                })
            })
        }
    }

    private handlePlayerChange(playerId: string): void {
        const player = this.state.players.find(player => player.id === playerId );
        // console.log(player?.rounds[this.state.currentRound]);
        if (player) {
            this.setState({
                currentPlayer: playerId,
                golfScore: player.score,
                currentHole: this.currentHole(player),
                playerName: player.name,
            }, () => {
                this.setState({
                    currentPar: player.rounds[this.state.currentRound].scores[this.state.currentHole].par,
                    currentStrokes: player.rounds[this.state.currentRound].scores[this.state.currentHole].strokes,
                    yds: player.gender === '1' ? DEFAULT_YDS['1'][this.state.currentHole] : DEFAULT_YDS['2'][this.state.currentHole],
                    forBall: this.forBall(player.rounds[this.state.currentRound].scores[this.state.currentHole].par, player.rounds[this.state.currentRound].scores[this.state.currentHole].strokes),
                    position1: this.state.players[0].position,
                    position2: this.state.players[1].position,
                    position3: this.state.players[2].position,
                    position4: this.state.players[3].position,
                    position5: this.state.players[4].position,
                    position6: player.position,
                    name1: this.state.players[0].name,
                    name2: this.state.players[1].name,
                    name3: this.state.players[2].name,
                    name4: this.state.players[3].name,
                    name5: this.state.players[4].name,
                    name6: player.name,
                    golfScore1: this.state.players[0].score,
                    golfScore2: this.state.players[1].score,
                    golfScore3: this.state.players[2].score,
                    golfScore4: this.state.players[3].score,
                    golfScore5: this.state.players[4].score,
                    golfScore6: player.score,
                    thru1: this.state.players[0].rounds[this.state.currentRound].thru,
                    thru2: this.state.players[1].rounds[this.state.currentRound].thru,
                    thru3: this.state.players[2].rounds[this.state.currentRound].thru,
                    thru4: this.state.players[3].rounds[this.state.currentRound].thru,
                    thru5: this.state.players[4].rounds[this.state.currentRound].thru,
                    thru6: player.rounds[this.state.currentRound].thru,
                    today1: this.state.players[0].rounds[this.state.currentRound].today,
                    today2: this.state.players[1].rounds[this.state.currentRound].today,
                    today3: this.state.players[2].rounds[this.state.currentRound].today,
                    today4: this.state.players[3].rounds[this.state.currentRound].today,
                    today5: this.state.players[4].rounds[this.state.currentRound].today,
                    today6: player.rounds[this.state.currentRound].today,
                    hole1: player.rounds[this.state.currentRound].scores[0].strokes,
                    hole2: player.rounds[this.state.currentRound].scores[1].strokes,
                    hole3: player.rounds[this.state.currentRound].scores[2].strokes,
                    hole4: player.rounds[this.state.currentRound].scores[3].strokes,
                    hole5: player.rounds[this.state.currentRound].scores[4].strokes,
                    hole6: player.rounds[this.state.currentRound].scores[5].strokes,
                    hole7: player.rounds[this.state.currentRound].scores[6].strokes,
                    hole8: player.rounds[this.state.currentRound].scores[7].strokes,
                    hole9: player.rounds[this.state.currentRound].scores[8].strokes,
                    holeTotal9: this.holeTotal(player.rounds[this.state.currentRound].scores, 0, 9),
                    hole10: player.rounds[this.state.currentRound].scores[9].strokes,
                    hole11: player.rounds[this.state.currentRound].scores[10].strokes,
                    hole12: player.rounds[this.state.currentRound].scores[11].strokes,
                    hole13: player.rounds[this.state.currentRound].scores[12].strokes,
                    hole14: player.rounds[this.state.currentRound].scores[13].strokes,
                    hole15: player.rounds[this.state.currentRound].scores[14].strokes,
                    hole16: player.rounds[this.state.currentRound].scores[15].strokes,
                    hole17: player.rounds[this.state.currentRound].scores[16].strokes,
                    hole18: player.rounds[this.state.currentRound].scores[17].strokes,
                    holeTotal18: this.holeTotal(player.rounds[this.state.currentRound].scores, 9, 18),
                })
            })
        }
    }

    private holeTotal(playerRound: Score[], start: number, end: number): number {
        let holeTotal = 0;
        playerRound.slice(start, end).forEach(value => {
            holeTotal += value.strokes;
        })
        return holeTotal;
    }

    private currentHole(player: Player): number {
        const currentHole = player.rounds[this.state.currentRound].thru + player.rounds[this.state.currentRound].startingTee
        return currentHole > 18 ? currentHole - 18 : currentHole;
    }

    private forBall(currentPar: number, currentStrokes: number): string {
        if (currentPar < currentStrokes) {
            return (currentStrokes - currentPar) === 1 ? 'Bogie' : '';
        } else if (currentPar === currentStrokes) {
            return 'For Par';
        } else {
            const difference = currentPar - currentStrokes;
            return difference === 1 ? 'Birdie' : difference === 2 ? 'Eagle' : currentStrokes === 1 ? '1st shot' : currentStrokes === 2 ? '2nd shot' : currentStrokes === 3 ? '3rd shot' : currentStrokes === 4 ? '4th shot' : currentStrokes === 5 ? '5th shot' : '6th shot';
        }
    }

    private showGolfPersonalAchievement(): void {
        this.setState({
            showGolfPersonalAchievement: !this.state.showGolfPersonalAchievement
        }, () => {
            golfService.showGolfPersonalAchievement(socket, this.state);
        })
    }

    private setGolfPersonalAchievement(): void {
        golfService.setGolfPersonalAchievement(socket, this.state);
    }

    private handleShowForBallChange(showForBall: boolean): void {
        this.setState({
            showForBall: showForBall
        })
    }

    private showGolfCurrentRank(): void {
        this.setState({
            showGolfCurrentRank: !this.state.showGolfCurrentRank
        }, () => {
            golfService.showGolfCurrentRank(socket, this.state);
        })
    }

    private setGolfCurrentRank(): void {
        golfService.setGolfCurrentRank(socket, this.state)
    }

    private showGolfIndividualCaption(): void {
        this.setState({
            showGolfIndividualCaption: !this.state.showGolfIndividualCaption
        }, () =>{
            golfService.showGolfIndividualCaption(socket, this.state.showGolfIndividualCaption, this.state.playerName, this.state.currentHole, this.state.golfScore, this.state.currentPar, this.state.currentStrokes, this.state.yds, this.state.forBall);
        })
    }

    private setGolfIndividualCaption(): void {
        golfService.setGolfIndividualCaption(socket, this.state.playerName, this.state.currentHole, this.state.golfScore, this.state.currentPar, this.state.currentStrokes, this.state.yds, this.state.forBall);
    }

    private showGolfLogo(): void {
        this.setState({
            showGolfLogo: !this.state.showGolfLogo
        }, () => {
            golfService.showGolfLogo(socket, this.state.showGolfLogo, this.state.golfLogoUrl);
        })
    }

    private setGolfLogo(): void {

    }

    private showGolfCaption(): void {
        this.setState({
            showGolfCaption: !this.state.showGolfCaption,
        }, () => {
            golfService.showGolfCaption(socket, this.state.showGolfCaption, this.state.golfCaptionTitle, this.state.golfCaption1, this.state.golfTransparency);
        })
    }

    private setGolfCaption(): void {
        golfService.setGolfCaption(socket, this.state.golfCaptionTitle, this.state.golfCaption1, this.state.golfTransparency);
    }

    private showWeather(): void {
        this.setState({
            showGolfWeather: !this.state.showGolfWeather
        }, () => {
            golfService.showWeather(socket, this.state.showGolfWeather, this.state.golfWeather, this.state.golfTemperature, this.state.golfWind, this.state.golfWindDirection);
        })
    }

    private setWeather(): void {
        golfService.setWeather(socket, this.state.golfWeather, this.state.golfTemperature, this.state.golfWind, this.state.golfWindDirection);
    }
    // private setTotalScore(values: Score): void {
    //     let totalScore = this.state.totalScore;
    //     if (values.id) {
    //         this.state.totalScore.forEach((c, index) => {
    //             if (c.id === values.id) {
    //                 totalScore[index] = values;
    //                 this.setState({
    //                     totalScore: totalScore,
    //                 })
    //             }
    //         })
    //     } else {
    //         totalScore[totalScore.length] = values;
    //     }
    // }
}

import React from 'react';
import { io, Socket } from 'socket.io-client';
import './captionPreview.scss';
import {
    CountDownData,
    CaptionData,
    LogoUrlData,
    ScoreData,
    RefreshData,
    ShowCaption,
    ShowOrBeginCountDown,
    ShowScoreCard,
    ShowLogo,
    GolfCaptionData,
    ShowGolfCaption,
    GolfWeatherData,
    ShowGolfWeather,
    ShowGolfIndividualCaption,
    ShowGolfCurrentRank,
    GolfCurrentRankData,
    GolfIndividualCaptionData,
    ShowGolfPersonalAchievement,
    GolfPersonalAchievementData
} from '../../common/types';
import { CAPTION_BASE_URL } from '../../common/constants';
import { notNull } from "../../common/utils";
import weather from "./weather.png";
import temperature from "./temperature.png";
import wind from "./wind.png";
import windDirection from "./windDirection.png";

interface captionShowProps {
    roomId: string
}
interface captionShowState {
    score1: number;
    score2: number;
    team1: string;
    team2: string;
    display: boolean;
    transparency: number;
    captionTitle: string;
    caption1: string;
    caption2: string;
    showCaption: boolean;
    showScoreCard: boolean;
    showCountDown: boolean;
    showLogo: boolean;
    minute: number;
    second: number;
    beginCountDown: boolean;
    logoUrl: string;
    textWidth: number;
    showGolfIndividualCaption: boolean;
    showGolfCaption: boolean;
    showGolfPersonalAchievement: boolean;
    golfTransparency: number;
    golfCaptionTitle: string;
    golfCaption1: string;
    golfWindDirection: string;
    golfWind: string;
    golfWeather: string;
    golfTemperature: number;
    showGolfWeather: boolean;
    playerName: string;
    currentHole: number;
    golfScore: number;
    currentPar: number;
    currentStrokes: number;
    yds: number;
    forBall: string;
    strokes: number[];
    showGolfCurrentRank: boolean;
    position1: number;
    position2: number;
    position3: number;
    position4: number;
    position5: number;
    position6: number;
    name1: string;
    name2: string;
    name3: string;
    name4: string;
    name5: string;
    name6: string;
    golfScore1: number;
    golfScore2: number;
    golfScore3: number;
    golfScore4: number;
    golfScore5: number;
    golfScore6: number;
    thru1: number;
    thru2: number;
    thru3: number;
    thru4: number;
    thru5: number;
    thru6: number;
    today1: number;
    today2: number;
    today3: number;
    today4: number;
    today5: number;
    today6: number;
    hole1: number;
    hole2: number;
    hole3: number;
    hole4: number;
    hole5: number;
    hole6: number;
    hole7: number;
    hole8: number;
    hole9: number;
    holeTotal9: number;
    hole10: number;
    hole11: number;
    hole12: number;
    hole13: number;
    hole14: number;
    hole15: number;
    hole16: number;
    hole17: number;
    hole18: number;
    holeTotal18: number;
}

let timer : NodeJS.Timeout;
let socket: Socket;
const SOCKET_PATH = '/captionserver/socket.io';
const DEFAULT_PAR = [4, 5, 3, 4, 3, 4, 4, 5, 4, 4, 3, 4, 4, 5, 4, 3, 5, 4];


export class CaptionPreview extends React.Component<captionShowProps, captionShowState>{
    private marqueeRef: React.RefObject<HTMLDivElement> = React.createRef();
    private marqueeTextRef: React.RefObject<HTMLSpanElement> = React.createRef();
    constructor(props: captionShowProps) {
        super(props);
        this.state = {
            score1: 0,
            score2: 0,
            team1: '',
            team2: '',
            display: false,
            transparency: 0,
            captionTitle: '',
            caption1: '',
            caption2: '',
            showCaption: false,
            showScoreCard: false,
            showCountDown: false,
            showLogo: false,
            minute: 0,
            second: 0,
            beginCountDown: false,
            logoUrl: '',
            textWidth: 0,
            showGolfIndividualCaption: false,
            showGolfCaption: false,
            showGolfPersonalAchievement: false,
            golfTransparency: 0,
            golfCaptionTitle: '',
            golfCaption1: '',
            golfWindDirection: '',
            golfWind: '',
            golfWeather: '',
            golfTemperature: 30,
            showGolfWeather: false,
            playerName: '',
            currentHole: 1,
            golfScore: 0,
            currentPar: 1,
            currentStrokes: 1,
            yds: 0,
            forBall: '1st shot',
            strokes: [],
            showGolfCurrentRank: false,
            position1: 1,
            position2: 2,
            position3: 3,
            position4: 4,
            position5: 5,
            position6: 0,
            name1: '',
            name2: '',
            name3: '',
            name4: '',
            name5: '',
            name6: '',
            golfScore1: 0,
            golfScore2: 0,
            golfScore3: 0,
            golfScore4: 0,
            golfScore5: 0,
            golfScore6: 0,
            thru1: 0,
            thru2: 0,
            thru3: 0,
            thru4: 0,
            thru5: 0,
            thru6: 0,
            today1: 0,
            today2: 0,
            today3: 0,
            today4: 0,
            today5: 0,
            today6: 0,
            hole1: 0,
            hole2: 0,
            hole3: 0,
            hole4: 0,
            hole5: 0,
            hole6: 0,
            hole7: 0,
            hole8: 0,
            hole9: 0,
            holeTotal9: 0,
            hole10: 0,
            hole11: 0,
            hole12: 0,
            hole13: 0,
            hole14: 0,
            hole15: 0,
            hole16: 0,
            hole17: 0,
            hole18: 0,
            holeTotal18: 0,
        }
    }
    componentDidMount() {
        socket = io(CAPTION_BASE_URL, {
            path: SOCKET_PATH,
            transports: ['websocket'],
            query: {
                roomId: this.props.roomId
            }
        });
        socket.on("controller", (data) => {
            switch (data.type) {
                case 'refreshData':
                    this.refresh(data);
                    break;
            }
        });
        socket.on("controller", (data) => {
            switch (data.type) {
                case 'caption':
                    this.setCaption(data);
                    break;
                case 'showCaption':
                    this.setShowCaption(data);
                    break;
                case 'logoUrl':
                    this.setLogoUrl(data);
                    break;
                case 'score':
                    this.setScore(data);
                    break;
                case 'countDown':
                    this.setCountDown(data);
                    break;
                case 'showCountDown':
                    this.setShowCountDown(data);
                    break;
                case 'beginCountDown':
                    this.setBeginCountDown(data);
                    break;
                case 'showScoreCard':
                    this.setShowScoreCard(data);
                    break;
                case 'showLogo':
                    this.setShowLogo(data);
                    break;
                case 'showGolfIndividualCaption':
                    this.showGolfIndividualCaption(data);
                    break;
                case 'setGolfIndividualCaption':
                    this.setGolfIndividualCaption(data);
                    break;
                case 'setGolfCaption':
                    this.setGolfCaption(data);
                    break;
                case 'showGolfCaption':
                    this.showGolfCaption(data);
                    break;
                case 'showWeather':
                    this.showWeather(data);
                    break;
                case 'setWeather':
                    this.setWeather(data);
                    break;
                case 'showGolfCurrentRank':
                    this.showGolfCurrentRank(data);
                    break;
                case 'setGolfCurrentRank':
                    this.setGolfCurrentRank(data);
                    break;
                case 'showGolfPersonalAchievement':
                    this.showGolfPersonalAchievement(data);
                    break;
                case 'setGolfPersonalAchievement':
                    this.setGolfPersonalAchievement(data);
                    break;
            }
        });
    }

    private parTotal(defaultPar: number[], start: number, end: number): number {
        let parTotal = 0;
        defaultPar.slice(start, end).forEach(value => {
            parTotal += value;
        })
        return parTotal;
    }

    private showGolfPersonalAchievement(showGolfPersonalAchievement: ShowGolfPersonalAchievement): void {
        this.setState({
            showGolfPersonalAchievement: showGolfPersonalAchievement.display,
            playerName: showGolfPersonalAchievement.playerName,
            golfScore: showGolfPersonalAchievement.golfScore,
            hole1: showGolfPersonalAchievement.hole1,
            hole2: showGolfPersonalAchievement.hole2,
            hole3: showGolfPersonalAchievement.hole3,
            hole4: showGolfPersonalAchievement.hole4,
            hole5: showGolfPersonalAchievement.hole5,
            hole6: showGolfPersonalAchievement.hole6,
            hole7: showGolfPersonalAchievement.hole7,
            hole8: showGolfPersonalAchievement.hole8,
            hole9: showGolfPersonalAchievement.hole9,
            holeTotal9: showGolfPersonalAchievement.holeTotal9,
            hole10: showGolfPersonalAchievement.hole10,
            hole11: showGolfPersonalAchievement.hole11,
            hole12: showGolfPersonalAchievement.hole12,
            hole13: showGolfPersonalAchievement.hole13,
            hole14: showGolfPersonalAchievement.hole14,
            hole15: showGolfPersonalAchievement.hole15,
            hole16: showGolfPersonalAchievement.hole16,
            hole17: showGolfPersonalAchievement.hole17,
            hole18: showGolfPersonalAchievement.hole18,
            holeTotal18: showGolfPersonalAchievement.holeTotal18,
        })
    }

    private setGolfPersonalAchievement(golfPersonalAchievementData: GolfPersonalAchievementData): void {
        this.setState({
            playerName: golfPersonalAchievementData.playerName,
            golfScore: golfPersonalAchievementData.golfScore,
            hole1: golfPersonalAchievementData.hole1,
            hole2: golfPersonalAchievementData.hole2,
            hole3: golfPersonalAchievementData.hole3,
            hole4: golfPersonalAchievementData.hole4,
            hole5: golfPersonalAchievementData.hole5,
            hole6: golfPersonalAchievementData.hole6,
            hole7: golfPersonalAchievementData.hole7,
            hole8: golfPersonalAchievementData.hole8,
            hole9: golfPersonalAchievementData.hole9,
            holeTotal9: golfPersonalAchievementData.holeTotal9,
            hole10: golfPersonalAchievementData.hole10,
            hole11: golfPersonalAchievementData.hole11,
            hole12: golfPersonalAchievementData.hole12,
            hole13: golfPersonalAchievementData.hole13,
            hole14: golfPersonalAchievementData.hole14,
            hole15: golfPersonalAchievementData.hole15,
            hole16: golfPersonalAchievementData.hole16,
            hole17: golfPersonalAchievementData.hole17,
            hole18: golfPersonalAchievementData.hole18,
            holeTotal18: golfPersonalAchievementData.holeTotal18,
        })
    }

    private showGolfCurrentRank(showGolfCurrentRank: ShowGolfCurrentRank): void {
        this.setState({
            showGolfCurrentRank: showGolfCurrentRank.display,
            position1: showGolfCurrentRank.position1,
            position2: showGolfCurrentRank.position2,
            position3: showGolfCurrentRank.position3,
            position4: showGolfCurrentRank.position4,
            position5: showGolfCurrentRank.position5,
            position6: showGolfCurrentRank.position6,
            name1: showGolfCurrentRank.name1,
            name2: showGolfCurrentRank.name2,
            name3: showGolfCurrentRank.name3,
            name4: showGolfCurrentRank.name4,
            name5: showGolfCurrentRank.name5,
            name6: showGolfCurrentRank.name6,
            golfScore1: showGolfCurrentRank.golfScore1,
            golfScore2: showGolfCurrentRank.golfScore2,
            golfScore3: showGolfCurrentRank.golfScore3,
            golfScore4: showGolfCurrentRank.golfScore4,
            golfScore5: showGolfCurrentRank.golfScore5,
            golfScore6: showGolfCurrentRank.golfScore6,
            thru1: showGolfCurrentRank.thru1,
            thru2: showGolfCurrentRank.thru2,
            thru3: showGolfCurrentRank.thru3,
            thru4: showGolfCurrentRank.thru4,
            thru5: showGolfCurrentRank.thru5,
            thru6: showGolfCurrentRank.thru6,
            today1: showGolfCurrentRank.today1,
            today2: showGolfCurrentRank.today2,
            today3: showGolfCurrentRank.today3,
            today4: showGolfCurrentRank.today4,
            today5: showGolfCurrentRank.today5,
            today6: showGolfCurrentRank.today6,
        })
    }

    private setGolfCurrentRank(golfCurrentRankData: GolfCurrentRankData): void {
        this.setState({
            position1: golfCurrentRankData.position1,
            position2: golfCurrentRankData.position2,
            position3: golfCurrentRankData.position3,
            position4: golfCurrentRankData.position4,
            position5: golfCurrentRankData.position5,
            position6: golfCurrentRankData.position6,
            name1: golfCurrentRankData.name1,
            name2: golfCurrentRankData.name2,
            name3: golfCurrentRankData.name3,
            name4: golfCurrentRankData.name4,
            name5: golfCurrentRankData.name5,
            name6: golfCurrentRankData.name6,
            golfScore1: golfCurrentRankData.golfScore1,
            golfScore2: golfCurrentRankData.golfScore2,
            golfScore3: golfCurrentRankData.golfScore3,
            golfScore4: golfCurrentRankData.golfScore4,
            golfScore5: golfCurrentRankData.golfScore5,
            golfScore6: golfCurrentRankData.golfScore6,
            thru1: golfCurrentRankData.thru1,
            thru2: golfCurrentRankData.thru2,
            thru3: golfCurrentRankData.thru3,
            thru4: golfCurrentRankData.thru4,
            thru5: golfCurrentRankData.thru5,
            thru6: golfCurrentRankData.thru6,
            today1: golfCurrentRankData.today1,
            today2: golfCurrentRankData.today2,
            today3: golfCurrentRankData.today3,
            today4: golfCurrentRankData.today4,
            today5: golfCurrentRankData.today5,
            today6: golfCurrentRankData.today6,
        })
    }

    private showWeather(showGolfWeather: ShowGolfWeather): void {
        this.setState({
            showGolfWeather: showGolfWeather.display,
            golfWindDirection: showGolfWeather.golfWindDirection,
            golfWind: showGolfWeather.golfWind,
            golfWeather: showGolfWeather.golfWeather,
            golfTemperature: showGolfWeather.golfTemperature
        })
    }

    private setWeather(golfWeatherData: GolfWeatherData): void {
        this.setState({
            golfWindDirection: golfWeatherData.golfWindDirection,
            golfWind: golfWeatherData.golfWind,
            golfWeather: golfWeatherData.golfWeather,
            golfTemperature: golfWeatherData.golfTemperature
        })
    }

    private setGolfCaption(golfCaptionData: GolfCaptionData): void {
        this.setState({
            golfCaptionTitle: golfCaptionData.golfCaptionTitle,
            golfCaption1: golfCaptionData.golfCaption1,
            golfTransparency: golfCaptionData.golfTransparency
        })
    }

    private showGolfCaption(showGolfCaption: ShowGolfCaption): void {
        this.setState({
            showGolfCaption: showGolfCaption.display,
            golfCaptionTitle: showGolfCaption.golfCaptionTitle,
            golfCaption1: showGolfCaption.golfCaption1,
            golfTransparency: showGolfCaption.golfTransparency
        })
    }

    private showGolfIndividualCaption(showGolfIndividualCaption: ShowGolfIndividualCaption): void {
        this.setState({
            showGolfIndividualCaption: showGolfIndividualCaption.display,
            playerName: showGolfIndividualCaption.playerName,
            currentHole: showGolfIndividualCaption.currentHole,
            golfScore: showGolfIndividualCaption.golfScore,
            currentPar: showGolfIndividualCaption.currentPar,
            currentStrokes: showGolfIndividualCaption.currentStrokes,
            yds: showGolfIndividualCaption.yds,
            forBall: showGolfIndividualCaption.forBall
        }, () => {
            this.setStrokes();
        })
    }

    private setGolfIndividualCaption(golfIndividualCaption: GolfIndividualCaptionData): void {
        this.setState({
            playerName: golfIndividualCaption.playerName,
            currentHole: golfIndividualCaption.currentHole,
            golfScore: golfIndividualCaption.golfScore,
            currentPar: golfIndividualCaption.currentPar,
            currentStrokes: golfIndividualCaption.currentStrokes,
            yds: golfIndividualCaption.yds,
            forBall: golfIndividualCaption.forBall
        }, () => {
            this.setStrokes();
        })
    }

    private setStrokes(): void {
        const count = this.state.currentStrokes > this.state.currentPar ? this.state.currentStrokes : this.state.currentPar
        const strokes: number[] = [];
        for (let i = 1; i <= count; i++) {
            strokes.push(i);
        }
        this.setState({
            strokes: strokes
        })
    }

    private setCountDown(countDownData: CountDownData): void {
        this.setState({
            minute: countDownData.minute,
            second: countDownData.second
        });

    }

    private setShowCountDown(showCountDown: ShowOrBeginCountDown): void {
        this.setState({
            showCountDown: showCountDown.display
        })
        if (!this.state.minute && !this.state.second) {
            this.setState({
                minute: showCountDown.minute,
                second: showCountDown.second,
            })
        }
    }

    private setCaption(captionData: CaptionData): void {
        this.setState({
            transparency: captionData.transparency,
            captionTitle: captionData.captionTitle,
            caption1: captionData.caption1,
            caption2: captionData.caption2
        }, () => {
            this.setState({
                textWidth: notNull(this.marqueeTextRef.current).offsetWidth,
            })
        })
    }

    private setShowCaption(showCaption: ShowCaption): void {
        this.setState({
            showCaption: showCaption.display,
            transparency: showCaption.transparency,
            captionTitle: showCaption.captionTitle,
            caption1: showCaption.caption1,
            caption2: showCaption.caption2
        }, () => {
            this.setState({
                textWidth: notNull(this.marqueeTextRef.current).offsetWidth,
            }, () => {
                console.log(this.state.textWidth);
                this.marquee();
            })
        })
    }

    private setLogoUrl(logoUrlData: LogoUrlData): void {
        this.setState({
            logoUrl: logoUrlData.logoUrl
        });
    }

    private setScore(scoreData: ScoreData): void {
        this.setState({
            team1: scoreData.team1,
            team2: scoreData.team2,
            score1: scoreData.score1,
            score2: scoreData.score2
        })
    }

    private setBeginCountDown(beginCountDown: ShowOrBeginCountDown): void {
        if (beginCountDown.display && !this.state.minute && !this.state.second) {
            this.setState({
                minute: beginCountDown.minute,
                second: beginCountDown.second,
            })
        }
        beginCountDown.display ? this.timerInterval() : clearInterval(timer);
    }

    private setShowScoreCard(showScoreCard: ShowScoreCard): void {
        this.setState({
            showScoreCard: showScoreCard.display,
            team1: showScoreCard.team1,
            team2: showScoreCard.team2,
            score1: showScoreCard.score1,
            score2: showScoreCard.score2
        })
    }

    private setShowLogo(showLogo: ShowLogo): void {
        this.setState({
            showLogo: showLogo.display,
            logoUrl: showLogo.logoUrl
        })
    }

    private timerInterval(): void {
        timer = setInterval(() => {
            if (this.state.minute > 0 || this.state.second > 0) {
                this.state.second === 0
                    ?
                    this.setState({
                        minute: this.state.minute - 1,
                        second: 59
                    })
                    :
                    this.setState({
                        second: this.state.second - 1,
                    })

            }else {
                clearInterval(timer);
            }
        },1000);
    }

    private refresh(refreshData: RefreshData): void {
        this.setState({
            score1: refreshData.score1,
            score2: refreshData.score2,
            team1: refreshData.team1,
            team2: refreshData.team2,
            display: refreshData.display,
            transparency: refreshData.transparency,
            captionTitle: refreshData.captionTitle,
            caption1: refreshData.caption1,
            caption2: refreshData.caption2,
            showCaption: refreshData.showCaption,
            showScoreCard: refreshData.showScoreCard,
            showCountDown: refreshData.showCountDown,
            showLogo: refreshData.showLogo,
            minute: refreshData.minute,
            second: refreshData.second,
            beginCountDown: refreshData.beginCountDown,
            logoUrl: refreshData.logoUrl,
            textWidth: notNull(this.marqueeTextRef.current).offsetWidth,
        }, () => {
            if (this.state.beginCountDown) {
                this.timerInterval();
            }
            this.marquee();
        })
    }

    public render(): JSX.Element {
        return (
            <div className='previewHome'>
                <div className='golfPreview'>
                    <div className='individualRanking' style={{display: `${this.state.showGolfCurrentRank ? 'block' : 'none'}`}}>
                        <div className='Ranking'>
                            <div className='Ranking5'>
                                <div className='RankingRanking'>
                                    <span>{this.state.position1}</span>
                                </div>
                                <div className='RankingName'>
                                    <span>{this.state.name1}</span>
                                </div>
                                <div className='RankingTotal'>
                                    <span>{this.state.golfScore1}</span>
                                </div>
                                <div className='RankingToday'>
                                    <span>{this.state.today1}</span>
                                </div>
                            </div>
                            <div className='Ranking5'>
                                <div className='RankingRanking'>
                                    <span>{this.state.position2}</span>
                                </div>
                                <div className='RankingName'>
                                    <span>{this.state.name2}</span>
                                </div>
                                <div className='RankingTotal'>
                                    <span>{this.state.golfScore2}</span>
                                </div>
                                <div className='RankingToday'>
                                    <span>{this.state.today2}</span>
                                </div>
                            </div>
                            <div className='Ranking5'>
                                <div className='RankingRanking'>
                                    <span>{this.state.position3}</span>
                                </div>
                                <div className='RankingName'>
                                    <span>{this.state.name3}</span>
                                </div>
                                <div className='RankingTotal'>
                                    <span>{this.state.golfScore3}</span>
                                </div>
                                <div className='RankingToday'>
                                    <span>{this.state.today3}</span>
                                </div>
                            </div>
                            <div className='Ranking5'>
                                <div className='RankingRanking'>
                                    <span>{this.state.position4}</span>
                                </div>
                                <div className='RankingName'>
                                    <span>{this.state.name4}</span>
                                </div>
                                <div className='RankingTotal'>
                                    <span>{this.state.golfScore4}</span>
                                </div>
                                <div className='RankingToday'>
                                    <span>{this.state.today4}</span>
                                </div>
                            </div>
                            <div className='Ranking5'>
                                <div className='RankingRanking'>
                                    <span>{this.state.position5}</span>
                                </div>
                                <div className='RankingName'>
                                    <span>{this.state.name5}</span>
                                </div>
                                <div className='RankingTotal'>
                                    <span>{this.state.golfScore5}</span>
                                </div>
                                <div className='RankingToday'>
                                    <span>{this.state.today5}</span>
                                </div>
                            </div>
                        </div>
                        <div className='RankingBottom'>
                            <div className='RankingRanking'>
                                <span>{this.state.position6}</span>
                            </div>
                            <div className='RankingName'>
                                <span>{this.state.name6}</span>
                            </div>
                            <div className='RankingTotal'>
                                <span>{this.state.golfScore6}</span>
                            </div>
                            <div className='RankingToday'>
                                <span>{this.state.today6}</span>
                            </div>
                        </div>
                    </div>
                    <div className='individualScore' style={{display: `${this.state.showGolfPersonalAchievement ? 'block' : 'none'}`}}>
                        <div className='individualScore1'>
                            <div className='individualScoreImg'>

                            </div>
                            <div className='individualScoreTitle'>
                                <span>{this.state.playerName}</span>
                            </div>
                            <div className='individualScoreTotal'>
                                <span>{this.state.golfScore}</span>
                            </div>
                        </div>
                        <div className='individualScore2'>
                            <div className='individualScoreDiv'>
                                <div className='individualScorePar9'>
                                    {
                                        DEFAULT_PAR.slice(0, 9).map((c, index) =>
                                            <div key={index} className='individualScorePar'>
                                                <span>{c}</span>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className='total9'><span>{this.parTotal(DEFAULT_PAR, 0, 9)}</span></div>
                                <div className='individualScorePar18'>
                                    {
                                        DEFAULT_PAR.slice(9, 18).map((c, index) =>
                                            <div key={index} className='individualScorePar'>
                                                <span>{c}</span>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className='total18'><span>{this.parTotal(DEFAULT_PAR, 9, 18)}</span></div>
                                <div className='parTotal'>
                                    <span>{this.parTotal(DEFAULT_PAR, 0, 18)}</span>
                                </div>
                            </div>
                        </div>
                        <div className='individualScore3'>
                            <div className='individualScorePar9'>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole1 === DEFAULT_PAR[0] || this.state.hole1 === 0) ? '' :this.state.hole1 < DEFAULT_PAR[0] ? 'show1' : 'show2'}`}>{this.state.hole1 === 0 ? '' : this.state.hole1}</span>
                                </div>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole2 === DEFAULT_PAR[1] || this.state.hole2 === 0) ? '' :this.state.hole2 < DEFAULT_PAR[1] ? 'show1' : 'show2'}`}>{this.state.hole2 === 0 ? '' : this.state.hole2}</span>
                                </div>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole3 === DEFAULT_PAR[2] || this.state.hole3 === 0) ? '' :this.state.hole3 < DEFAULT_PAR[2] ? 'show1' : 'show2'}`}>{this.state.hole3 === 0 ? '' : this.state.hole3}</span>
                                </div>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole4 === DEFAULT_PAR[3] || this.state.hole4 === 0) ? '' :this.state.hole4 < DEFAULT_PAR[3] ? 'show1' : 'show2'}`}>{this.state.hole4 === 0 ? '' : this.state.hole4}</span>
                                </div>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole5 === DEFAULT_PAR[4] || this.state.hole5 === 0) ? '' :this.state.hole5 < DEFAULT_PAR[4] ? 'show1' : 'show2'}`}>{this.state.hole5 === 0 ? '' : this.state.hole5}</span>
                                </div>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole6 === DEFAULT_PAR[5] || this.state.hole6 === 0) ? '' :this.state.hole6 < DEFAULT_PAR[5] ? 'show1' : 'show2'}`}>{this.state.hole6 === 0 ? '' : this.state.hole6}</span>
                                </div>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole7 === DEFAULT_PAR[6] || this.state.hole7 === 0) ? '' :this.state.hole7 < DEFAULT_PAR[6] ? 'show1' : 'show2'}`}>{this.state.hole7 === 0 ? '' : this.state.hole7}</span>
                                </div>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole8 === DEFAULT_PAR[7] || this.state.hole8 === 0) ? '' :this.state.hole8 < DEFAULT_PAR[7] ? 'show1' : 'show2'}`}>{this.state.hole8 === 0 ? '' : this.state.hole8}</span>
                                </div>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole9 === DEFAULT_PAR[8] || this.state.hole9 === 0) ? '' :this.state.hole9 < DEFAULT_PAR[8] ? 'show1' : 'show2'}`}>{this.state.hole9 === 0 ? '' : this.state.hole9}</span>
                                </div>
                            </div>
                            <div className='total9'><span>{this.state.holeTotal9 === 0 ? '' : this.state.holeTotal9}</span></div>
                            <div className='individualScorePar18'>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole10 === DEFAULT_PAR[9] || this.state.hole10 === 0) ? '' :this.state.hole10 < DEFAULT_PAR[9] ? 'show1' : 'show2'}`}>{this.state.hole10 === 0 ? '' : this.state.hole10}</span>
                                </div>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole11 === DEFAULT_PAR[10] || this.state.hole11 === 0) ? '' :this.state.hole11 < DEFAULT_PAR[10] ? 'show1' : 'show2'}`}>{this.state.hole11 === 0 ? '' : this.state.hole11}</span>
                                </div>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole12 === DEFAULT_PAR[11] || this.state.hole12 === 0) ? '' :this.state.hole12 < DEFAULT_PAR[11] ? 'show1' : 'show2'}`}>{this.state.hole12 === 0 ? '' : this.state.hole12}</span>
                                </div>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole13 === DEFAULT_PAR[12] || this.state.hole13 === 0) ? '' :this.state.hole13 < DEFAULT_PAR[12] ? 'show1' : 'show2'}`}>{this.state.hole13 === 0 ? '' : this.state.hole13}</span>
                                </div>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole14 === DEFAULT_PAR[13] || this.state.hole14 === 0) ? '' :this.state.hole14 < DEFAULT_PAR[13] ? 'show1' : 'show2'}`}>{this.state.hole14 === 0 ? '' : this.state.hole14}</span>
                                </div>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole15 === DEFAULT_PAR[14] || this.state.hole15 === 0) ? '' :this.state.hole15 < DEFAULT_PAR[14] ? 'show1' : 'show2'}`}>{this.state.hole15 === 0 ? '' : this.state.hole15}</span>
                                </div>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole16 === DEFAULT_PAR[15] || this.state.hole16 === 0) ? '' :this.state.hole16 < DEFAULT_PAR[15] ? 'show1' : 'show2'}`}>{this.state.hole16 === 0 ? '' : this.state.hole16}</span>
                                </div>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole17 === DEFAULT_PAR[16] || this.state.hole17 === 0) ? '' :this.state.hole17 < DEFAULT_PAR[16] ? 'show1' : 'show2'}`}>{this.state.hole17 === 0 ? '' : this.state.hole17}</span>
                                </div>
                                <div className='individualScorePar'>
                                    <span className={`individualScoreParSpan ${(this.state.hole18 === DEFAULT_PAR[17] || this.state.hole18 === 0) ? '' :this.state.hole18 < DEFAULT_PAR[17] ? 'show1' : 'show2'}`}>{this.state.hole18 === 0 ? '' : this.state.hole18}</span>
                                </div>
                            </div>
                            <div className='total18'><span>{this.state.holeTotal18 === 0 ? '' : this.state.holeTotal18}</span></div>
                        </div>
                    </div>
                    <div className='golfCaption' style={{display: `${this.state.showGolfCaption ? 'flex' : 'none'}`}}>
                        <div className='golfImgDiv'>
                            <img alt=''/>
                        </div>
                        <div className='golfTitle'>
                            <div className='golfTitle1'>
                                <span>{this.state.golfCaptionTitle}</span>
                            </div>
                            <div className='golfTitle2'>
                                <span>{this.state.golfCaption1}</span>
                            </div>
                        </div>
                    </div>
                    <div className='individualCaption' style={{display: `${this.state.showGolfIndividualCaption ? 'block' : 'none'}`}}>
                        <div className='individualCaption1'>
                            <div className='individualCaptionImg'>
                                <img alt=''/>
                            </div>
                            <div className='individualCaptionTitle'>
                                <span>{this.state.playerName}</span>
                            </div>
                            <div className='individualCaptionTotalScore'>
                                <span>{this.state.golfScore}</span>
                            </div>
                        </div>
                        <div className='individualCaption2'>
                            <div className='currentHole'>
                                <span>HOLE {this.state.currentHole}</span>
                            </div>
                            <div className='parsDiv'>
                                {
                                    this.state.strokes.map(c =>
                                        <div className='pars'>
                                            <span className={`par ${c === this.state.currentStrokes ? (c <= this.state.currentPar ? 'show1' : 'show2') : ''}`}>{c}</span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className='individualCaption3'>
                            <span>{this.state.yds}YDS</span>
                            <span>{this.state.forBall}</span>
                        </div>
                    </div>
                    <div className='scoreList'>
                        <div className='listTop'>
                            <div className='imgDiv'>
                                <img alt=''/>
                            </div>
                            <div className='title'>
                                <div className='title1'>
                                    <span>2022年bmw杯国际高尔夫球赛</span>
                                </div>
                                <div className='title2'>
                                    <span>中国区域赛</span>
                                </div>
                            </div>
                        </div>
                        <div className='form0'>
                            <div className='round'>
                                <div className='roundDiv'>ROUND 1</div>
                            </div>
                            <div className='total'>TOTAL</div>
                            <div className='part'>PART 72</div>
                            <div className='today'>TODAY</div>
                        </div>
                        <div className='form'>
                            <div className='round'>
                                <div className='roundDiv'>1</div>
                                <div className='name'>zkxjn</div>
                            </div>
                            <div className='total'>-14</div>
                            <div className='part'>9</div>
                            <div className='today'>-5</div>
                        </div>
                        <div className='form'>
                            <div className='round'>
                                <div className='roundDiv'>1</div>
                                <div className='name'>zkxjn</div>
                            </div>
                            <div className='total'>-14</div>
                            <div className='part'>9</div>
                            <div className='today'>-5</div>
                        </div>

                    </div>
                    <div className='weatherDiv' style={{display: `${this.state.showGolfWeather ? 'block' : 'none'}`}}>
                        <div className='weatherTop'>
                            <img className='weather' src={weather} alt=''/>
                            <span>{this.state.golfWeather}</span>
                            <img className='temperature' src={temperature} alt=''/>
                            <span>{this.state.golfTemperature}</span>
                        </div>
                        <div className='weatherBottom'>
                            <img className='wind' src={wind} alt=''/>
                            <span>{this.state.golfWind}</span>
                            <img className='windDirection' src={windDirection} alt=''/>
                            <span>{this.state.golfWindDirection}</span>
                        </div>
                    </div>
                    <div className='logo'>

                    </div>
                </div>
                <div className='scoreCard'>
                    <div className={`countDown ${this.state.showCountDown ? '' : 'noShow'}`}>
                        <span>
                            {this.state.minute + ':' + (this.state.second <  10 ? '0' + this.state.second : this.state.second)}
                        </span>
                    </div>
                    <div className={`team ${this.state.showScoreCard ? 'showTeam' : ''}`}>
                        <div className='type'>
                            <span>
                                直播
                            </span>
                        </div>
                        <span style={{paddingLeft: '0.0866em', whiteSpace: 'pre'}}>{this.state.team1}</span>
                        <span style={{paddingLeft: '0.0866em'}}>{this.state.score1 + '-'}</span>
                        <span>{this.state.score2}</span>
                        <span style={{whiteSpace: 'pre'}}>{this.state.team2}</span>
                    </div>
                </div>
                <div className='captionShow'
                     style={{display: `${this.state.showCaption ? 'flex' : 'none'}`, opacity: `${(100 -this.state.transparency)/100}`}}>
                    <div className='captionTitle'>
                        <span>{this.state.captionTitle.length > 2
                            ? this.state.captionTitle.slice(0,2).replace(' ', '\u3000') + '\n' + this.state.captionTitle.slice(2).replace(' ', '\u3000')
                            : this.state.captionTitle}</span>
                    </div>
                    <div>
                        <div className='caption1'>
                            <span>{this.state.caption1}</span>
                        </div>
                        <div className='caption2'>
                            <div className='orange'/>
                            <div ref={this.marqueeRef} className='marquee'>
                                <span ref={this.marqueeTextRef} className='marquee_text'>{this.state.caption2}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <img className='logo'
                     style={{display: `${this.state.showLogo ? 'block' : 'none'}`}}
                     alt={''}
                     src={this.state.logoUrl}/>
            </div>
        )
    }

    private marquee(): void {
        let scrollWidth = notNull(this.marqueeRef.current).offsetWidth;
        let coordinate = scrollWidth;
        window.requestAnimationFrame(() => this.change(coordinate, scrollWidth));
    }

    private change(coordinate: number, scrollWidth: number): any {
        coordinate = coordinate - 0.33;
        if (coordinate < -this.state.textWidth) {
            coordinate = scrollWidth;
        }
        notNull(this.marqueeTextRef.current).style.left = coordinate + "px";
        window.requestAnimationFrame(() => this.change(coordinate, scrollWidth));
    }
}

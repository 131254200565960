import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Home as GuangXiLiveNewHome } from './page/guangxilivenew/Home';
import { Layout } from './page/info/Layout/Layout';
import { Video } from './page/info/video/Video';
import { VideoDetails } from './page/info/video/VideoDetails';
import { Home as InfoHome } from './page/info/home/Home';
import { EuvpPlayer } from './page/info/euvp/EuvpPlayer';
import { Login as InfoLogin } from "./page/info/login/Login";
import { Caption } from './page/caption/caption';
import { CaptionShow } from './page/captionShow/captionShow';
import { GolfController } from "./page/caption/golf";
import { BasketballShow } from "./page/basketballShow/BasketballShow";

export class App extends React.Component {
  public render() {
    return (
        <div className="App">
          <Switch>
            <Route key="guangxilivenew" path="/guangxilivenew" exact component={GuangXiLiveNewHome} />
            <Route key="info" path="/info" exact component={InfoLogin} />
            <Route key="caption" path="/caption" exact component={Caption} />
            <Route key="golf" path="/golf" exact component={GolfController} />
            <Route key="captionShow" path="/captionShow" exact component={CaptionShow} />
            <Route key="basketballShow" path="/basketballShow" exact component={BasketballShow} />
            <Route key="euvp" path="/euvp/:videoId" exact component={(props: any) => <EuvpPlayer {...props} />} />
            <Route path="/info">
              <Layout>
                <Switch>
                  <Route key="infoHome" path="/info/home" exact component={InfoHome} />
                  <Route key="infoVideos" path="/info/videos" exact component={Video} />
                  <Route key="infoVideoDetails" path="/info/video/:videoId" exact component={(props: any) => <VideoDetails {...props} />} />
                </Switch>
              </Layout>
            </Route>
          </Switch>
        </div>
    );
  }
}

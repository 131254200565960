import './VideoCard.scss';
import cover from './cover.png';
import video from './video.png';
import React from 'react';
import { VideoResponse } from '../../../../common/types';
import { formatDate } from '../../../../common/utils';
import { history } from '../../../../common/history';

interface VideoCardProps {
  video: VideoResponse;
}

export class VideoCard extends React.Component<VideoCardProps> {
  render(): JSX.Element {
    return (
      <div className="VideoCard" onClick={() => this.handleShowVideoDetails(this.props.video.id) }>
        <img alt="" src={ cover }/>
        <div className="MediaType">
          <label>Media Communication</label>
          <img alt="MediaType" src={ video } />
        </div>
        <div className="Date">{ formatDate(this.props.video.scheduleTime) }</div>
        <div className="Title">{ this.props.video.name }</div>
      </div>
    );
  }

  // noinspection JSMethodCanBeStatic
  private handleShowVideoDetails(videoId: string){
    history.push(`/info/video/${videoId}`)
  }
}
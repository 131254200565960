import React from "react";
import './BasketballShow.scss';
import { io, Socket } from "socket.io-client";
import { CAPTION_BASE_URL } from "../../common/constants";
import {
    BasketballCaptionData,
    BasketballCompetitorData,
    BasketballLogoData,
    BasketballScoreCardData,
    BasketballTotalScoreData,
    CommentaryData,
    ShowBasketballCaption,
    ShowBasketballCompetitor,
    ShowBasketballLogo,
    ShowBasketballScoreCard,
    ShowBasketballTotalScore,
    ShowCommentary, TeamMembers
} from "../../common/types";
import {notNull} from "../../common/utils";

interface BasketballProps {
    roomId: string
}

interface BasketballState {
    commentator: string;
    guest: string;
    venue: string;
    showCommentary: boolean;
    team1Logo: string;
    team2Logo: string;
    name1: string;
    name2: string;
    score1: number;
    score2: number;
    showBasketballScoreCard: boolean;
    basketballLogoUrl: string;
    competition: string;
    word: string;
    showBasketballLogo: boolean;
    showBasketballCaption: boolean;
    title: string;
    text: string;
    showBasketballCompetitor: boolean;
    team1Members: TeamMembers;
    team2Members: TeamMembers;
    currentTeamMembers: TeamMembers;
    currentTeam: number;
    showBasketballTotalScore: boolean;
}

let socket: Socket;
const SOCKET_PATH = '/captionserver/socket.io';

export class BasketballShow extends React.Component<BasketballProps, BasketballState>{

    constructor(props: BasketballProps) {
        super(props);
        this.state = {
            commentator: '',
            guest: '',
            venue: '',
            showCommentary: false,
            team1Logo: '',
            team2Logo: '',
            name1: '主队',
            name2: '客队',
            score1: 0,
            score2: 0,
            showBasketballScoreCard: false,
            basketballLogoUrl: '',
            competition: '',
            word: '',
            showBasketballLogo: false,
            showBasketballCaption: false,
            title: '',
            text: '',
            showBasketballCompetitor: false,
            team1Members: {},
            team2Members: {},
            currentTeamMembers: {},
            currentTeam: 0,
            showBasketballTotalScore: false,
        }
    }

    public componentDidMount(): void {
        socket = io(CAPTION_BASE_URL, {
            path: SOCKET_PATH,
            transports: ['websocket'],
            query: {
                roomId: notNull(new URLSearchParams(window.location.search).get('id')),
            }
        });
        socket.on('controller', (data) => {
            switch (data.type) {
                case 'listenBasketball':
                    this.refreshBack();
                    break;
                case 'showCommentary':
                    this.showCommentary(data);
                    break;
                case 'setCommentary':
                    this.setCommentary(data);
                    break;
                case 'showBasketballScoreCard':
                    this.previewBasketballScoreCard(data);
                    break;
                case 'setBasketballScoreCard':
                    this.setBasketballScoreCard(data);
                    break;
                case 'showBasketballLogo':
                    this.showBasketballLogo(data);
                    break;
                case 'setBasketballLogo':
                    this.setBasketballLogo(data);
                    break;
                case 'showBasketballCaption':
                    this.showBasketballCaption(data);
                    break;
                case 'setBasketballCaption':
                    this.setBasketballCaption(data);
                    break;
                case 'showBasketballCompetitor':
                    this.showBasketballCompetitor(data);
                    break;
                case 'setBasketballCompetitor':
                    this.setBasketballCompetitor(data);
                    break;
                case 'showBasketballTotalScore':
                    this.showBasketballTotalScore(data);
                    break;
                case 'setBasketballTotalScore':
                    this.setBasketballTotalScore(data);
                    break;
            }
        })
    }

    private refreshBack(): void {
        socket.emit('refresh', {
            type: 'basketballRefreshData',
            commentator: this.state.commentator,
            guest: this.state.guest,
            venue: this.state.venue,
            showCommentary: this.state.showCommentary,
            team1Logo: this.state.team1Logo,
            team2Logo: this.state.team2Logo,
            name1: this.state.name1,
            name2: this.state.name2,
            score1: this.state.score1,
            score2: this.state.score2,
            showBasketballScoreCard: this.state.showBasketballScoreCard,
            basketballLogoUrl: this.state.basketballLogoUrl,
            competition: this.state.competition,
            word: this.state.word,
            showBasketballLogo: this.state.showBasketballLogo,
            showBasketballCaption: this.state.showBasketballCaption,
            title: this.state.title,
            text: this.state.text,
            showBasketballCompetitor: this.state.showBasketballCompetitor,
            team1Members: this.state.team1Members,
            team2Members: this.state.team2Members,
            currentTeam: this.state.currentTeam,
            showBasketballTotalScore: this.state.showBasketballTotalScore
        })
    }

    private showBasketballTotalScore(showBasketballTotalScore: ShowBasketballTotalScore): void {
        this.setState({
            showBasketballTotalScore: showBasketballTotalScore.showBasketballTotalScore,
            competition: showBasketballTotalScore.competition,
            word: showBasketballTotalScore.word,
            name1: showBasketballTotalScore.name1,
            name2: showBasketballTotalScore.name2,
            score1: showBasketballTotalScore.score1,
            score2: showBasketballTotalScore.score2,
            team1Logo: showBasketballTotalScore.team1Logo,
            team2Logo: showBasketballTotalScore.team2Logo,
        })
    }

    private setBasketballTotalScore(basketballTotalScoreData: BasketballTotalScoreData): void {
        this.setState({
            competition: basketballTotalScoreData.competition,
            word: basketballTotalScoreData.word,
            name1: basketballTotalScoreData.name1,
            name2: basketballTotalScoreData.name2,
            score1: basketballTotalScoreData.score1,
            score2: basketballTotalScoreData.score2,
            team1Logo: basketballTotalScoreData.team1Logo,
            team2Logo: basketballTotalScoreData.team2Logo,
        })
    }

    private showBasketballCompetitor(showBasketballCompetitor: ShowBasketballCompetitor): void {
        this.setState({
            showBasketballCompetitor: showBasketballCompetitor.showBasketballCompetitor,
            currentTeamMembers: showBasketballCompetitor.currentTeam === 0 ? showBasketballCompetitor.team1Members : showBasketballCompetitor.team2Members,
            team1Members: showBasketballCompetitor.team1Members,
            team2Members: showBasketballCompetitor.team2Members,
            name1: showBasketballCompetitor.name1,
            name2: showBasketballCompetitor.name2,
            team1Logo: showBasketballCompetitor.team1Logo,
            team2Logo: showBasketballCompetitor.team2Logo,
            currentTeam: showBasketballCompetitor.currentTeam
        })
    }

    private setBasketballCompetitor(basketballCompetitorData: BasketballCompetitorData): void {
        this.setState({
            currentTeamMembers: basketballCompetitorData.currentTeam === 0 ? basketballCompetitorData.team1Members : basketballCompetitorData.team2Members,
            team1Members: basketballCompetitorData.team1Members,
            team2Members: basketballCompetitorData.team2Members,
            name1: basketballCompetitorData.name1,
            name2: basketballCompetitorData.name2,
            team1Logo: basketballCompetitorData.team1Logo,
            team2Logo: basketballCompetitorData.team2Logo,
            currentTeam: basketballCompetitorData.currentTeam
        })
    }

    private showBasketballCaption(showBasketballCaption: ShowBasketballCaption): void {
        this.setState({
            showBasketballCaption: showBasketballCaption.showBasketballCaption,
            title: showBasketballCaption.title,
            text: showBasketballCaption.text
        })
    }

    private setBasketballCaption(basketballCaptiondata: BasketballCaptionData): void {
        this.setState({
            title: basketballCaptiondata.title,
            text: basketballCaptiondata.text
        })
    }

    private showBasketballLogo(showBasketballLogo: ShowBasketballLogo): void {
        this.setState({
            showBasketballLogo: showBasketballLogo.showBasketballLogo,
            basketballLogoUrl: showBasketballLogo.basketballLogoUrl,
            competition: showBasketballLogo.competition
        })
    }

    private setBasketballLogo(basketballLogoData: BasketballLogoData): void {
        this.setState({
            basketballLogoUrl: basketballLogoData.basketballLogoUrl,
            competition: basketballLogoData.competition
        })
    }

    private showCommentary(showCommentary: ShowCommentary): void {
        this.setState({
            commentator: showCommentary.commentator,
            guest: showCommentary.guest,
            venue: showCommentary.venue,
            showCommentary: showCommentary.showCommentary
        })
    }

    private setCommentary(commentaryData: CommentaryData): void {
        this.setState({
            commentator: commentaryData.commentator,
            guest: commentaryData.guest,
            venue: commentaryData.venue,
        })
    }

    private previewBasketballScoreCard(showBasketballScoreCard: ShowBasketballScoreCard): void {
        this.setState({
            showBasketballScoreCard: showBasketballScoreCard.showBasketballScoreCard,
            team1Logo: showBasketballScoreCard.team1Logo,
            team2Logo: showBasketballScoreCard.team2Logo,
            name1: showBasketballScoreCard.name1,
            name2: showBasketballScoreCard.name2,
            score1: showBasketballScoreCard.score1,
            score2: showBasketballScoreCard.score2,
            word: showBasketballScoreCard.word
        })
    }

    private setBasketballScoreCard(basketballScoreCardData: BasketballScoreCardData): void {
        this.setState({
            team1Logo: basketballScoreCardData.team1Logo,
            team2Logo: basketballScoreCardData.team2Logo,
            name1: basketballScoreCardData.name1,
            name2: basketballScoreCardData.name2,
            score1: basketballScoreCardData.score1,
            score2: basketballScoreCardData.score2,
            word: basketballScoreCardData.word
        })
    }

    public render(): JSX.Element {
        return (
            <div className='basketballShowHome'>
                <div className='basketballShow'>
                    <div className='totalSorceDiv' style={{display: `${this.state.showBasketballTotalScore ? 'block' : 'none'}`}}>
                        <div className='sorceTop'>
                            <div className='teamLogo'>
                                <img alt='' src={this.state.team1Logo}/>
                            </div>
                            <div className='description'>
                                <div className='nameDiv'>
                                    {this.state.competition}
                                </div>
                                <div className='teamDiv'>
                                    <div className='team1'>
                                        {this.state.name1}
                                    </div>
                                    <div className='sorce1'>
                                        {this.state.score1}
                                    </div>
                                    <div className='sorceBottom'>
                                        <div className='bar'>
                                            {this.state.word}
                                        </div>
                                    </div>
                                    <div className='sorce2'>
                                        {this.state.score2}
                                    </div>
                                    <div className='team2'>
                                        {this.state.name2}
                                    </div>
                                </div>
                            </div>
                            <div className='teamLogo'>
                                <img alt='' src={this.state.team2Logo}/>
                            </div>
                        </div>
                    </div>
                    <div className='firstTeam' style={{display: `${this.state.showBasketballCompetitor ? 'block' : 'none'}`}}>
                        <div className='top'>
                            <div className='topBar'>
                                首发阵容 FIRST TEAM
                            </div>
                        </div>
                        <div className='information'>
                            <div className='log'>
                                <img alt='' src={this.state.currentTeam === 0 ? this.state.team1Logo : this.state.team2Logo}/>
                            </div>
                            <div className='teamName'>
                                {this.state.currentTeam === 0 ? this.state.name1 : this.state.name2}
                            </div>
                            <div className='line'/>
                            <div className='teamMemberDiv'>
                                {
                                    this.state.currentTeamMembers.member1 &&
                                    (this.state.currentTeamMembers.member1.name ||
                                    this.state.currentTeamMembers.member1.number ||
                                    this.state.currentTeamMembers.member1.phonetic) &&
                                    <div className='teamMember'>
                                        <div className='number'>
                                            {this.state.currentTeamMembers.member1.number}
                                        </div>
                                        <div className='nameDiv'>
                                            <div className='name'>
                                                {this.state.currentTeamMembers.member1.name}
                                            </div>
                                            <div className='pinyin'>
                                                {this.state.currentTeamMembers.member1.phonetic}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.currentTeamMembers.member2 &&
                                    (this.state.currentTeamMembers.member2.name ||
                                        this.state.currentTeamMembers.member2.number ||
                                        this.state.currentTeamMembers.member2.phonetic) &&
                                    <div className='teamMember'>
                                        <div className='number'>
                                            {this.state.currentTeamMembers.member2.number}
                                        </div>
                                        <div className='nameDiv'>
                                            <div className='name'>
                                                {this.state.currentTeamMembers.member2.name}
                                            </div>
                                            <div className='pinyin'>
                                                {this.state.currentTeamMembers.member2.phonetic}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.currentTeamMembers.member3 &&
                                    (this.state.currentTeamMembers.member3.name ||
                                        this.state.currentTeamMembers.member3.number ||
                                        this.state.currentTeamMembers.member3.phonetic) &&
                                    <div className='teamMember'>
                                        <div className='number'>
                                            {this.state.currentTeamMembers.member3.number}
                                        </div>
                                        <div className='nameDiv'>
                                            <div className='name'>
                                                {this.state.currentTeamMembers.member3.name}
                                            </div>
                                            <div className='pinyin'>
                                                {this.state.currentTeamMembers.member3.phonetic}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.currentTeamMembers.member4 &&
                                    (this.state.currentTeamMembers.member4.name ||
                                        this.state.currentTeamMembers.member4.number ||
                                        this.state.currentTeamMembers.member4.phonetic) &&
                                    <div className='teamMember'>
                                        <div className='number'>
                                            {this.state.currentTeamMembers.member4.number}
                                        </div>
                                        <div className='nameDiv'>
                                            <div className='name'>
                                                {this.state.currentTeamMembers.member4.name}
                                            </div>
                                            <div className='pinyin'>
                                                {this.state.currentTeamMembers.member4.phonetic}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.currentTeamMembers.member5 &&
                                    (this.state.currentTeamMembers.member5.name ||
                                        this.state.currentTeamMembers.member5.number ||
                                        this.state.currentTeamMembers.member5.phonetic) &&
                                    <div className='teamMember'>
                                        <div className='number'>
                                            {this.state.currentTeamMembers.member5.number}
                                        </div>
                                        <div className='nameDiv'>
                                            <div className='name'>
                                                {this.state.currentTeamMembers.member5.name}
                                            </div>
                                            <div className='pinyin'>
                                                {this.state.currentTeamMembers.member5.phonetic}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='live' style={{display: `${this.state.showBasketballLogo ? 'flex' : 'none'}`}}>
                        <div className='log'>
                            <img alt='' src={this.state.basketballLogoUrl}/>
                        </div>
                        <div className='name'>
                            {this.state.competition}
                        </div>
                        <div className='titlebar'>
                            直播
                        </div>
                    </div>
                    <div className='subtitleStrip' style={{display: `${this.state.showBasketballCaption ? 'block' : 'none'}`}}>
                        <div className='top'>
                            {this.state.title}
                        </div>
                        <div className='bottom'>
                            <div className='textbar'/>
                            <div className='text'>
                                {this.state.text}
                            </div>
                        </div>
                    </div>
                    <div className='commentary' style={{display: `${this.state.showCommentary ? 'block' : 'none'}`}}>
                        <div className='commentaryDiv'>
                            <div className='commentator'>
                            <span>
                                解说：
                            </span>
                            <span>
                                {this.state.commentator}
                            </span>
                            </div>
                            <div className='guest'>
                            <span>
                                嘉宾：
                            </span>
                            <span>
                                {this.state.guest}
                            </span>
                            </div>
                        </div>
                        <div className='venue'>
                            {this.state.venue}
                        </div>
                    </div>
                    <div className='scoreCard' style={{display: `${this.state.showBasketballScoreCard ? 'flex' : 'none'}`}}>
                        <div className='left'>
                            <div className='log'>
                                <img
                                    alt=''
                                    src={this.state.team1Logo}
                                />
                            </div>
                            <div className='log'>
                                <img
                                    alt=''
                                    src={this.state.team2Logo}
                                />
                            </div>
                        </div>
                        <div className='among'>
                            <div className='team1'>
                                <div className='name'>
                                    {this.state.name1}
                                </div>
                                <div className='score'>
                                    <span>
                                        {this.state.score1}
                                    </span>
                                </div>
                            </div>
                            <div className='team2'>
                                <div className='name'>
                                    {this.state.name2}
                                </div>
                                <div className='score'>
                                    <span>
                                        {this.state.score2}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.word &&
                            <div className='right'>
                                <div className='bar'>
                                    {this.state.word}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

import './ContentView.scss';
import React from "react";
import { Content } from '../../common/types';
import { Player } from './Player';

export interface ContentViewProps {
  content: Content;
  autoPlay: boolean;
  muted: boolean;
  active: boolean;
}

export class ContentView extends React.Component<ContentViewProps> {

  public render() {
    return (
      <div className="ContentView">
        <div className="Bottom">
            <div className="TitleAndTag">
                <div style={{width: '3px', height: '10px', margin: 'auto', background: '#02C5CD'}}/>
                <div className="Title">{this.props.content.name}</div>
                <div className="ScenicLevel">{this.props.content.scenicLevel}</div>
            </div>
        </div>
        <div className="VideoContainer">
          {
            this.props.active ?
            <Player
              muted={this.props.muted}
              autoPlay={this.props.autoPlay}
              videoSource={this.props.content.streamUrl}
              audioSource={this.props.content.backgroundMusic}
              cover={this.props.content.cover}
              description={this.props.content.description}
            /> :
            <img
              src={this.props.content.cover}
              alt=''
              style={{ width: '100%', height: '100%' }}
            />
          }
        </div>
      </div>
    );
  }
}

import React from 'react';
import { Dropdown, Menu } from 'antd';
import { history } from '../../../../common/history';
import { authService } from '../../../../services/AuthService';

interface UserDropdownState {
  username?: string;
}

export class UserDropdown extends React.Component<unknown, UserDropdownState> {

  public componentDidMount(): void {
    this.setState({
      username: authService.currentUser(),
    })
  }

  public render(): JSX.Element {
    return (
      <div style={{ position: 'relative', height:"62px", float: "left", marginRight:"20px", lineHeight: "62px" }}>
        <Dropdown overlay={this.userMenu} trigger={['click']}>
          <span style={{ cursor: 'pointer' }} onClick={e => e.preventDefault()}>
            <b style={{ fontSize: '15px' }}>{this.state?.username || ''}&nbsp;&nbsp;</b>
            <i className="fas fa-chevron-down" style={{ marginRight: '10px' }} />
          </span>
        </Dropdown>
      </div>
    );
  }

  private userMenu = (
    <Menu>
      <Menu.Item key='userMenu' onClick={() => this.handleLogout()}>
        退出登录
      </Menu.Item>
    </Menu>
  );

  // noinspection JSMethodCanBeStatic
  private async handleLogout(): Promise<void> {
    await authService.logout();
    history.push('/info');
  }
}
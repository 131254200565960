import './Player.scss';
import React, { RefObject } from 'react';
import videojs from 'video.js';
import { createPlayer } from '../../common/backgroundMusicPlayer';
import { notNull } from '../../common/utils';
import Marquee from 'react-fast-marquee';

interface PlayerProps {
  muted: boolean;
  autoPlay: boolean;
  videoSource?: string;
  audioSource?: string;
  cover?: string;
  description?: string;
}

export class Player extends React.Component<PlayerProps> {
  private readonly playerContainerRef: RefObject<HTMLDivElement> = React.createRef();
  private readonly videoRef: RefObject<HTMLVideoElement> = React.createRef();
  private readonly audioRef: RefObject<HTMLAudioElement> = React.createRef();
  private readonly overlayRef: RefObject<HTMLDivElement> = React.createRef();
  private player?: videojs.Player;

  public componentDidMount(): void {
    this.player = createPlayer(notNull(this.videoRef.current), notNull(this.audioRef.current), {
      muted: this.props.muted,
      // @ts-ignore
      errorDisplay: false,
      controlBar: {
        playToggle: true,
        captionsButton: false,
        chaptersButton: false,
        subtitlesButton: false,
        remainingTimeDisplay: false,
        progressControl: false,
        pictureInPictureToggle: false,
        playbackRateMenuButton: false,
        fullscreenToggle: true,
        audioTrackButton: false,
        volumePanel: true,
      },
      html5: {
        vhs: {
          smoothQualityChange: false,
        },
      },
    });

    if (this.props.videoSource) {
      this.player.src({ src: this.props.videoSource });
    }

    this.player.on('fullscreenchange', () => {
      // show overlay after fullscreen.
      const overlay = this.playerContainerRef.current?.querySelector('.Overlay') as HTMLDivElement;
      if (overlay) {
        overlay.style.display = this.player?.isFullscreen() ? 'block' : 'none';
        // trigger overlay marquee to play
        window.dispatchEvent(new Event('resize'));
      }
      // switch to 720p+ immediately after fullscreen.
      if (this.player?.isFullscreen()) {
        (this.player.tech() as any)?.vhs.representations().forEach((rep: any) => {
          rep.enabled(rep.width >= 720);
        });
      } else {
        (this.player?.tech() as any)?.vhs.representations().forEach((rep: any) => {
          rep.enabled(true);
        });
      }
    });

    (this.player as any).overlay({
      content: this.overlayRef.current,
      align: 'bottom'
    });

    if (this.audioRef.current) {
      this.audioRef.current.muted = this.props.muted;
    }

    if (this.props.autoPlay) {
      this.player.play();
    }
  }

  public componentWillUnmount(): void {
    this.player?.pause();
    this.player?.dispose();
  }

  public render() {
    return (
      <div className="Player">
        <div className="PlayerContainer" ref={this.playerContainerRef}>
          <video
            ref={this.videoRef}
            className="video-js vjs-default-skin vjs-big-play-centered"
            poster={this.props.cover}
            controls
          />
          <audio
            ref={this.audioRef}
            style={{ display: 'none' }}
            src={this.props.audioSource}
            loop={true}
          />
        </div>
        <div className='Overlay' ref={this.overlayRef} style={{ display: 'none' }}>
          <Marquee gradient={false}>
            <div className='Description'>{this.props.description}</div>
          </Marquee>
        </div>
      </div>
    );
  }
}

import './Video.scss'
import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { VideoResponse, VideoStatus } from '../../../common/types';
import { VideoCard } from '../component/VideoCard/VideoCard';
import { videoService } from '../../../services/VideoService';

export interface VideoState {
  videos: VideoResponse[];
  selectedDate?: string;
}

export class Video extends React.Component<unknown, VideoState> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      videos: [],
    }
  }

  public async componentDidMount(): Promise<void> {
    this.setState({
      videos: (await videoService.getVideos()).filter( video => video.status === VideoStatus.vod || video.status === VideoStatus.live),
    })
  }

  public render(): JSX.Element {
    return (
     <div className="Video">
       <DatePicker placeholder="全部" onChange={(date: moment.Moment | null, selectedDate: string) => this.handleSelectDate(date, selectedDate)}/>
       <div className="VideoList">
         {
           this.state.videos.map((video) =>
             <VideoCard key={ video.id } video={ video } />
           )
         }
       </div>
     </div>
    )
  }

  private async handleSelectDate(date: moment.Moment | null, selectedDate: string): Promise<void> {
    this.setState({
      selectedDate: selectedDate
    },async () => {
      await this.refresh();
    });
  }

  private async refresh(): Promise<void> {
    this.setState({
      videos: (await videoService.getVideos(
        this.state.selectedDate || undefined
      )).filter( video => video.status === VideoStatus.vod || video.status === VideoStatus.live),
    });
  }
}

import axios from 'axios';
import { replaceUrlParams } from '../common/utils';
import { INFO_BASE_URL } from '../common/constants';
import { VideoResponse } from '../common/types';

const GET_VIDEO_URL = `${INFO_BASE_URL}/v1/videos/:videoId`;
const GET_VIDEOS_URL = `${INFO_BASE_URL}/v1/videos`;

class VideoService {

  public async getVideos(scheduleDate?: string): Promise<VideoResponse[]>{
    return (await axios.get(GET_VIDEOS_URL,{
      params: {
        ...scheduleDate && { scheduleDate: scheduleDate },
      }})).data as VideoResponse[];
  }

  public async getVideo(videoId: string): Promise<VideoResponse>{
    return (await axios.get(replaceUrlParams(GET_VIDEO_URL,{ videoId }))).data as VideoResponse;
  }
}

export const videoService = new VideoService();

import './Layout.scss'
import React from 'react';
import { Link } from 'react-router-dom';
import { UserDropdown } from '../component/UserDropdown/UserDropdown';

export interface LayoutProps {
  children: JSX.Element | JSX.Element[];
}

export class Layout extends React.Component<LayoutProps> {
  public render(): JSX.Element {
    return (
      <div className="Layout">
        <div className="Head">
          <div className="Menu">
            <ul>
              <li className="Entry">
                <Link to="/info/home">
                  <div className="Logo">
                    <span className="CN">云制宝</span>
                    <span className="EN">ICBT-CMT</span>
                  </div>
                  首页
                </Link>
              </li>
              <li><Link to="/info/home">新闻</Link></li>
              <li><Link to="/info/home">赛事</Link></li>
              <li><Link to="/info/videos">精彩回顾</Link></li>
              <li><Link to="/info/home">场馆</Link></li>
              <li><Link to="/info/home">代表团</Link></li>
              <li><Link to="/info/home">组委会</Link></li>
              <li><Link to="/info/home">图片</Link></li>
            </ul>
          </div>
          <div className="Search">
            <input type="text" />
            <button />
          </div>
          <UserDropdown/>
        </div>
        <div className="Content">
          {this.props.children}
        </div>
      </div>
    );
  }
}

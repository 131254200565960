import './index.scss';
import 'video.js/dist/video-js.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'antd/dist/antd.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import videojs from 'video.js';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { authService } from './services/AuthService';
import { history } from './common/history';

const overlay = require('videojs-overlay').default;
videojs.registerPlugin('overlay', overlay);

axios.interceptors.request.use(function (config) {
  const token = authService.getToken();
  if (token) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  throw error;
});

axios.interceptors.response.use(response => response, (error: { response?: Response}) => {
  if (window.location.pathname.match('/info') && error.response?.status === 401) {
    history.push('/info');
  }
  throw error;
});

ReactDOM.render(
    <React.StrictMode>
      <Router history={history}>
        <App />
      </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

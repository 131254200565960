export const PUBLISH_BASE_URL = process.env.REACT_APP_PUBLISH_BASE_URL || '';

export const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL || '';

export const HOST_NAME = process.env.REACT_APP_HOST_NAME || '';

export const HIDE_LOGIN = process.env.REACT_APP_HIDE_LOGIN === 'true';

export const GUANGXI_LIVE_HOSTNAME = 'guangxilive.icbtlive.com';

export const GUANGXI_LIVE_PUBLISH_ID = 'e1d280ff-d123-4256-97c5-79e7f529a3ce';

export const INFO_BASE_URL = process.env.REACT_APP_INFO_BASE_URL || '';

export const CAPTION_BASE_URL = process.env.REACT_APP_CAPTION_BASE_URL || '';

export const CAPTION_SHOW_URL = process.env.REACT_APP_CAPTION_SHOW_URL || '';

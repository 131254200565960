import './VideoDetails.scss'
import React from 'react';
import videojs from 'video.js';
import { RouteComponentProps } from 'react-router-dom';
import { Select, Spin } from 'antd';
import { formatDate, notNull } from '../../../common/utils';
import { VideoResponse, VideoStatus } from '../../../common/types';
import { videoService } from '../../../services/VideoService';

export interface VideoDetailsProps extends RouteComponentProps<{ videoId: string }> {

}

export interface VideoDetailsState {
  video: VideoResponse;
}

function getPlayerUrl(video: VideoResponse): string {
  return (video.status === VideoStatus.live ? video.channel?.publicUrl : video.vodUrl) as string;
}

export class VideoDetails extends React.Component<VideoDetailsProps, VideoDetailsState> {
  private videoRef = React.createRef<HTMLVideoElement>();
  private player?:videojs.Player;

  public async componentDidMount(): Promise<void> {
    const video = await videoService.getVideo(this.props.match.params.videoId);
    this.setState({
      video: video
    }, () => {
      this.player = videojs(notNull(this.videoRef.current));
      this.player.src(getPlayerUrl(this.state.video));
      this.player.muted(true);
      this.player.play();
    });
  }

  public componentWillUnmount(): void {
    this.player?.pause();
    this.player?.dispose();
  }

  public render(): JSX.Element {
    return (
      <div className="VideoDetails">
        {
          !this.state?.video ? <Spin /> :
          <div className="PlayerContainer">
            <video
              ref={this.videoRef}
              className="video-js vjs-big-play-centered"
              controls
            >
            </video>
            <div className="Operation">
              {
                this.state.video.languages &&
                <div className="Language">
                  <span>视频语言</span>
                  <Select key={this.state.video.id} className="Select" defaultValue={ this.state.video.languages[0] } onSelect={(value: any) => this.handleLanguageChange(value)}>
                    {
                      this.state.video.languages?.map((language, index) =>
                        <Select.Option key={index} value={language}>{language}</Select.Option>
                      )
                    }
                  </Select>
                </div>
              }
            </div>
            <div className="Date">{formatDate(this.state?.video.scheduleTime)}</div>
            <div style={{marginTop: "10px"}} >{this.state?.video.name}</div>
          </div>
        }
      </div>
    );
  }

  private handleLanguageChange(value: string): void {
    // @ts-ignore
    const audioTracks = this.player.audioTracks();
    for (let i = 0; i< audioTracks.length; i++) {
      if (audioTracks[i].label === value){
        audioTracks[i].enabled = true;
      }
    }
  }
}

import './Navigation.scss';
import React from 'react';
import { Input } from 'antd';

interface NavigationProps {
  visible: boolean;
  scenicPlace?: string;
  pageIndex: number;
  pageSize: number;
  totalContents: number
  handleCityChanged: (city: string) => void;
  handleSearch: (search?: string) => void;
  handlePageChanged: (pageIndex: number) => void;
}

interface NavigationState {
  search?: string;
}

const cities = [
  '全部',
  '南宁市',
  '柳州市',
  '桂林市',
  '梧州市',
  '北海市',
  '防城港市',
  '钦州市',
  '贵港市',
  '玉林市',
  '百色市',
  '贺州市',
  '河池市',
  '来宾市',
  '崇左市',
  '广旅专栏'
];

export class Navigation extends React.Component<NavigationProps, NavigationState> {

  public constructor(props: NavigationProps) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <div className="Navigation">
        <div className={`Content`}>
          <div className="SearchContainer">
            <Input
                className="Search"
                prefix={<i onClick={() => this.handleSearch()} className="fas fa-search" />}
                value={this.state.search}
                onChange={e => this.handleSearchChanged(e.target.value)}
                onPressEnter={() => this.handleSearch()}
            />
            <ul className="Menu">
              {
                cities.map((c, index) =>
                  <li
                    key={index}
                    className={`MenuItem ${(c === this.props.scenicPlace || (c === '全部' && !this.props.scenicPlace)) ? 'active' : ''}`}
                    onClick={() => this.props.handleCityChanged(c)}>
                    {c}
                  </li>)
              }
            </ul>
          </div>
        </div>
      </div>
    );
  }

  private handleSearchChanged(search: string): void {
    this.setState({
      search: search,
    });
  }

  private handleSearch(): void {
    this.props.handleSearch(this.state.search || undefined);
  }
}
